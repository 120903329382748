import styled from "styled-components";
import { primaryColor, themeWhite } from "../../constants";

const ImageList = styled.ul`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid black;
  margin: 10px;
  width: 20%;
  height: 450px;
  position: relative;
  overflow: hidden;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Desc = styled.p`
  background-color: ${themeWhite};
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
  margin: 0;
  text-decoration: none;
  color: ${primaryColor};
  text-align: center;
`;

const ImageModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  background-color: #2d2d2deb;
`;

const ModalImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CloseBtn = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  background-color: #ffffff;
  padding: 4px 12px;
  border-radius: 50%;
`;

export {
  ImageList,
  Item,
  Image,
  ImageModalContainer,
  ModalImage,
  CloseBtn,
  Desc,
};
