import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { getHeader, getMegaMenu } from "../actions";
import { HeaderResponse } from "../models";
import { MegaMenuResponse } from "../models/ApiResponses/MegaMenuResponse";

class HeaderClient extends BaseApiClient {
    public async getHeader(dispatch: Dispatch) {
    
        try {
            const headerData = await this.get<HeaderResponse>("public/category");
            dispatch(getHeader(headerData));
        } catch (error) {
            console.log(error);
        }
    }
    //lines added for mega menu.
    public async getMegaMenu(dispatch: Dispatch) {
        try {
          const megaMenuData = await this.get<MegaMenuResponse>("public/megaMenuData");
          dispatch(getMegaMenu(megaMenuData));
        } catch (error) {
          console.log(error);
        }
      }
}

export const headerClient = new HeaderClient();
