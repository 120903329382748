import React from "react";

interface TermsAndConditionsPageProps {}

interface TermsAndConditionsPageState {}

export class TermsAndConditionsPage extends React.Component<TermsAndConditionsPageProps, TermsAndConditionsPageState> {
  constructor(props: TermsAndConditionsPageProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>This is TermsAndConditions Page</div>;
  }
}
