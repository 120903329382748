import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { getCart } from "../actions";
import { CartResponse } from "../models";

class CartClient extends BaseApiClient {
  generateFormData(data: any) {
    let formData = new FormData();
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  }

  async getCart1() {
    try {
      const cartData = await this.get<CartResponse>("cart/get");
      return cartData.data;
    } catch (error) {
      console.log(error);
    }
  }
  public async getCart(dispatch: Dispatch) {
    try {
      const cartData = await this.get<CartResponse>("cart/get");
      dispatch(getCart(cartData));
    } catch (error) {
      console.log(error);
    }
  }

  public async addCart(data: any) {
    try {
      return await this.postJson<CartResponse>("cart/add", data);
    } catch (error) {
      return {
        data: null,
        error: "Something went wrong",
      };
    }
  } 

  public async copyCart(data: any) {
    try {
      return await this.postJson<CartResponse>("cart/clone", data);
    } catch (error) {
      return {
        data: null,
        error: "Something went wrong",
      };
    }
  }

  public async deleteCartItem(data: any) {
    try {
      return await this.deleteFormData<CartResponse>(
        "cart/delete",
        this.generateFormData({ _id: data._id })
      );
    } catch (error) {
      return {
        data: null,
        error: "Something went wrong",
      };
    }
  }

  async addQuotation(data: any) {
    try {
      return await this.postJson<CartResponse>("quotation/add", data);
    } catch (error) {
      return {
        data: null,
        error: "Something went wrong",
      };
    }
  }

  async addOrder(data: any) {
    try {
      return await this.postJson<CartResponse>("order/add", data);
    } catch (error) {
      return {
        data: null,
        error: "Something went wrong",
      };
    }
  }
}

export const cartClient = new CartClient();
