import { IAppliqueData } from "../models";
import { IAppliqueOptions } from "../reducer/applique.reducer";
import { AppliqueView } from "../widgets/shirtDesigner/Applique";

export const UPDATE_APPLIQUE_OPTION = "UPDATE_APPLIQUE_OPTION";
export const UPDATE_APPLIQUE_LIST = "UPDATE_APPLIQUE_LIST";
export const SELECT_APPLIQUE = "SELECT_APPLIQUE";
export const UPDATE_APPLIQUE_VIEW = "UPDATE_APPLIQUE_VIEW";

export function updateAppliqueOption(data: IAppliqueOptions) {
  return {
    type: UPDATE_APPLIQUE_OPTION,
    data,
  };
}

export function updateAppliqueList(data: IAppliqueData[]) {
  return {
    type: UPDATE_APPLIQUE_LIST,
    data,
  };
}

export function selectApplique(data: IAppliqueData) {
  return {
    type: SELECT_APPLIQUE,
    data,
  };
}

export function updateAppliqueRedux(appliqueView: AppliqueView) {
  return {
    type: UPDATE_APPLIQUE_VIEW,
    appliqueView,
  };
}
