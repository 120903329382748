import styled from 'styled-components';
import { MegaMenuContainer } from '../header/styled.component';


const MenuButton = styled.button`
  padding: 10px 20px;
  cursor: pointer;
`;

const Dropdown = styled.div`
  display:none;
  position: absolute;
  top: 70px;
  left: 0;
  background: white;
  // border: 1px solid #ccc;
  z-index: 1000;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  box-sizing:border-box;
    flex-wrap: wrap;
:a {
text-decoration:none:
}
  ${MegaMenuContainer}:hover & {
    visibility: visible;
    color:black;
    opacity: 1;
  }
  h3{
  color:rgb(20, 47, 143);
  }
`;

const Section = styled.div<{width?:number}>`
  padding: 20px;
  flex:${({ width }) => (width ? `0 0 ${width}%` : 1)} ;
   background: white;
       border-right: 1px solid #ddd;
`;

const LinkedList = styled.ul`
  list-style: none;
  padding: 0;
`;

const List = styled.li`
  padding: 10px;
    font-size: 18px;
        display: flex;
    align-items: center;
    gap: 10px;
`;
const CategoryContainer = styled.div<{isSelected:boolean}>`
    display: flex;
    gap: 20px;
    align-items: center;
    font-weight: bold; 
     color: ${({ isSelected }) => (isSelected ? 'rgb(20, 47, 142)' : 'rgb(2, 2, 2)')};

    &:hover {
        color: rgb(20, 47, 142); 
    }
    `;

const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    `;
const Title = styled.p`
    font-weight: 650;
    margin-top: 4px;
    cursor: pointer;
    &.product-list {
        color: rgb(20, 47, 142); 
    }; 
        
        &:hover {
            font-weight: bold;
        }
`;
const TitleProduct = styled.p<{isSelected?:boolean}>`
    font-weight: ${({ isSelected }) => (isSelected ? 750 : 450)};
    margin-top: 4px;
    cursor: pointer;
    &.product-list {
        color: rgb(20, 47, 142); 
    }; 
        
        &:hover {
            font-weight: bold;
        }
`;

const Price = styled.p`
    font-weight: 650;
    margin: 0;
    color: #8C1717; 
    
`;
const Description = styled.p`
margin: 0;
`;
const ProductDetail = styled.div`
  padding: 10px;
`;
const AddNow = styled.button`
height: 36px;
font-size: 15px;
border-radius: 10px;
background-color: #142f8f;
color: white;
padding: 10px;
`;
const ProductImage = styled.img`
max-width = 250px;
`;
const ProductContainer = styled.div`
${ProductImage}{
max-width:200px;
}
`;
const CategoryImage = styled.img`
border: 1px solid;
border-radius: 50%;
width: 10%;
 `;
const ProductLinks = styled.a`
text-decoration: none;
`;

 export {
    MenuButton,
    Dropdown,
    Section,
    LinkedList,
    List,
    CategoryContainer,
    DetailContainer,
    Title,
    Description,
    ProductDetail,
    ProductImage,
    CategoryImage,
    ProductContainer,
    AddNow,
    Price,
    ProductLinks,
    TitleProduct
 }