import { AnyAction } from "redux";
import { DECREMENT_LOADER, INCREMENT_LOADER } from "../actions/loader.action";
import { ILoaderState } from "../models/redux-models/ILoaderState";

const initialState: ILoaderState = {
  loaderCount: 0,
};

export function loader(
  state: ILoaderState = initialState,
  action: AnyAction
): ILoaderState {
  switch (action.type) {
    case INCREMENT_LOADER:
      return { ...state, loaderCount: state.loaderCount + 1 };
    case DECREMENT_LOADER:
      return { ...state, loaderCount: state.loaderCount - 1 };
    default:
      return state;
  }
}
