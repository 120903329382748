import styled from "styled-components";

const Container = styled.ul`
  margin: 40px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 30px 0 30px;
`;

const List = styled.li`
  list-style: none;
  border: 1px solid #b3b1b1cc;
  margin: 0 40px 20px 0;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #a1a0a0d1;
`;

const Item = styled.a`
  list-style: none;
  color: black;
  text-decoration: none;
  padding: 7px;
  display: block;
`;

const Img = styled.img`
  width: 185px;
  height: 172px;
  object-fit: cover;
`;

export { Container, List, Item, Img };
