import styled from "styled-components";

const Container = styled.div`
  padding: 0 30px;
`;

const Items = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
`;

const Item = styled.li`
  // display: flex;
  flex-direction: column;
  // margin: 0 20px 20px 20px;
  cursor: pointer;
  border: 1px solid #999;
  text-align: center;
`;

const Img = styled.img`
  width: 200px;
  object-fit: cover;
  // box-shadow: #edededd4 0px 0px 4px 1px;
`;

const Desc = styled.p`
  text-align: center;
  font-weight: 400;
  width: 200px;
  margin: 0 auto;
  color: #142f8f;
`;

const DesignHeader = styled.h1`
  font-size: 20px;
  font-weight: 700;
  color: #142f8f;
`;

const DesignContent = styled.p`
  margin-bottom: 4px;
`;

export { Container, Items, Item, Img, Desc, DesignHeader, DesignContent };
