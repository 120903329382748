import React from "react";

import {
  StyledInput,
  StyledButton,
  StyledDropdown,
  ButtonContainer,
  StyledTextarea,
  StyledContact
} from "./styled.component";

interface ContactFormProps {}

export const ContactForm: React.FC<ContactFormProps> = () => {
  return (
    <>
      <StyledContact> Contact</StyledContact>
      <p> Please fill out the following information </p>
      <p style={{color: "red"}}> * indicates required fields</p>
      <StyledInput type="text" placeholder="First Name *" autoComplete="off" />

      <StyledInput type="text" placeholder="Last Name *" autoComplete="off" />

      <StyledInput
        type="text"
        placeholder="Address Line 1"
        autoComplete="off"
      />

      <StyledInput
        type="text"
        placeholder="Address Line 2"
        autoComplete="off"
      />

      <StyledInput type="text" placeholder="City" autoComplete="off" />

      <StyledInput
        type="text"
        placeholder="State/Province/Region"
        autoComplete="off"
      />

      <StyledInput type="text" placeholder="Zip" autoComplete="off" />

      <StyledDropdown
        placeholder="Country"
        options={[
          { label: "United State", value: "UN" },
          { label: "Canada", value: "Canada" },
        ]}
        onChange={(val: any) => {
          console.log(val);
        }}
      />

      <StyledInput type="text" placeholder="Phone Number" autoComplete="off" />

      <StyledInput type="text" placeholder="Email" autoComplete="off" />

      <StyledInput type="text" placeholder="Organization" autoComplete="off" />

      <StyledInput type="text" placeholder="School" autoComplete="off" />

      <StyledTextarea
        rows={5}
        cols={86}
        placeholder="Enter qustions or comments here"
      />

      <ButtonContainer>
        <StyledButton>Submit</StyledButton>
        <StyledButton>Clear</StyledButton>
      </ButtonContainer>
      <div style={{marginBottom: "25px"}} />
    </>
  );
};
