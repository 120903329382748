import { IAddresRequestParams } from "../models";

export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const GET_PROFILE_BILLING_ADDRESSES = "GET_PROFILE_BILLING_ADDRESSES";
export const GET_PROFILE_SHIPPING_ADDRESSES = "GET_PROFILE_SHIPPING_ADDRESSES";

export const ADD_SHIPPING = "ADD_SHIPPING";
export const UPDATE_SHIPPING = "UPDATE_SHIPPING";
export const DELETE_SHIPPING = "DELETE_SHIPPING";

export const GET_SHIPPING_RATES = "GET_SHIPPING_RATES";

export function getProfile(data: any) {
  console.log("data --> : ", data);
  return {
    type: GET_PROFILE,
    data,
  };
}

export function updateProfile(data: any) {
  console.log("data --> : ", data);
  return {
    type: UPDATE_PROFILE,
    data,
  };
}

export function getProfileShippingAddresses(data: any) {
  console.log("data --> : ", data);
  return {
    type: GET_PROFILE_SHIPPING_ADDRESSES,
    data,
  };
}

export function getProfileBillingAddresses(data: any) {
  console.log("data --> : ", data);
  return {
    type: GET_PROFILE_BILLING_ADDRESSES,
    data,
  };
}

export function addShipping(data: IAddresRequestParams) {
  console.log("data --> : ", data);
  return {
    type: ADD_SHIPPING,
    data,
  };
}

export function updateShipping(data: IAddresRequestParams) {
  console.log("data --> : ", data);
  return {
    type: UPDATE_SHIPPING,
    data,
  };
}

export function deleteShipping(data: any) {
  console.log("data --> : ", data);
  return {
    type: DELETE_SHIPPING,
    data,
  };
}

export function getShippingRates(data: any) {
  console.log("data --> : ", data);
  return {
    type: GET_SHIPPING_RATES,
    data,
  };
}
