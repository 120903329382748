import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Title } from "../../components/Title";
import { Container, Img, Wrapper, Paragraph } from "./styled.component";
import { categoryClient } from "../../ApiClients/ProductClient";
import { IAppState, ProductResponseDatum } from "../../models";
import Card from "../../components/Card";

interface ClearancePageProps {
  product: any | ProductResponseDatum[];
  getProduct(): void;
}

interface ProductsPageState {}

export class ClearancePageComponent extends React.Component<
  ClearancePageProps,
  ProductsPageState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      product: [],
    };
    this.getData = this.getData.bind(this);
    this.groupByTimes = this.groupByTimes.bind(this);
  }

  componentDidMount(): void {
    this.props.getProduct();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.product !== prevProps.product) {
      this.getData(this.props.product);
    }
  }

  groupByTimes(data: any) {
    const productsData = Object.values(
      data.reduce((data: any, { key, value }: any) => {
        if (data[key]) data[key].value.push(value);
        else data[key] = { key, value: [value] };
        return data;
      }, {})
    );
    this.setState({
      product: productsData,
    });
  }

  getData(response: any) {
    let data: any = [];
    if (response) {
      for (let i = 0; i < response.length; i++) {
        data.push({
          key: response[i].sName,
          value: response[i],
        });
      }
      this.groupByTimes(data);
    }
  }

  render() {
    const { product }: any = this.state;
    return (
      <Container>
        {product &&
          product.map((prod: any) => (
            <>
              {<Title>Clearances{" " + prod.key}</Title>}
              <Wrapper>
                {prod.value.map((product: any) => (
                  <>
                    {product.clearance && (
                      <Card>
                        {product.colors && (
                          <Img src={product.colors[0].images.front} />
                        )}
                        {product.description && (
                          <Paragraph>{product.description}</Paragraph>
                        )}
                        {(product.currency || product.price) && (
                          <Paragraph>
                            {product.currency}
                            {product.price}
                          </Paragraph>
                        )}
                      </Card>
                    )}
                  </>
                ))}
              </Wrapper>
            </>
          ))}
      </Container>
    );
  }
}
const mapStateToProps = (state: IAppState) => {
  return {
    product: state.products.list,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getProduct: () => categoryClient.getProducts(dispatch),
  };
};

export const ClearancePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClearancePageComponent);
