import React from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";

import { primaryColor, fontOdd } from "../constants";

const Container = styled.div`
  margin: 20px;
  background-color: #142f8f17;
`;

const Wapper = styled.div`
  color: ${primaryColor};
  font-size: ${fontOdd(7)};
  font-style: italic;
  display: flex;
`;

const RightTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid ${primaryColor};
  padding-right: 30px;
`;

const Maquee: React.FC<
  Record<string, any> & { className?: string; children?: React.ReactNode }
> = ({ className, children, ...rest }) => {
  return (
    <Container className={className} {...rest}>
      <Wapper>
        <RightTriangle />
        <Marquee> {children}</Marquee>
      </Wapper>
    </Container>
  );
};

Maquee.propTypes = {};

Maquee.defaultProps = {};

export default Maquee;
