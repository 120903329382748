import styled from "styled-components";
import { primaryColor } from "../../constants";

const Container = styled.div`
  display:flex;
    overflow: scroll;

    
  &::-webkit-scrollbar {
    display: none;
}
`;
const StyledDiv = styled.div`
  
`;
const StyledPBlue = styled.p`
padding: 0px 30px;
  font-size: 20px;
  font-weight: 700;
  color: #142f8f;
`;
const StyledUl = styled.ul`
  font-weight: bold;
  color: Black;
`;
const StyledLi = styled.li`
  font-weight: 400;
  color: Blue;
`;

const AccordionCard = styled.ul`
  list-style: disc;
  overflow: hidden;
  font-size: 18px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const AccordionHeader = styled.li<{ active: boolean }>`
  color: ${primaryColor};
  cursor: pointer;

  & h5 {
    margin: 0;
  }
`;

const AccordionCollapse = styled.div<{ collapse: boolean }>`
  position: relative;
  overflow: hidden;
  transition: height 0.35s ease;
  height: ${(props) => (props.collapse ? "auto" : "0")};
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0 10px 10px;
`;

const FAQHeader = styled.p`
  font-weight: 700;
`;

export {
  Container,
  StyledPBlue,
  StyledUl,
  StyledLi,
  AccordionCard,
  AccordionHeader,
  AccordionCollapse,
  FAQHeader,
  StyledDiv
};
