import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { getSpecification } from "../actions";
import { SpecificationResponse } from "../models";

class SpecificationClient extends BaseApiClient {
  public async getSpecification(dispatch: Dispatch) {
    try {
      const specificationData = await this.get<SpecificationResponse>(
        "public/specification"
      );
      dispatch(getSpecification(specificationData));
    } catch (error) {
      console.log(error);
    }
  }
}

export const spectificationClient = new SpecificationClient();
