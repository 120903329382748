import React from "react";
import {
  Section,
  Logo,
  Desc,
  FormContainer,
  Title,
  SubTitle,
  Link,
  SignUpCta,
  SignUpLink,
} from "./styled.component";
import { LoginForm } from "./Login.form";
import LogoImg from "../../images/Logo.png";
import SZLogo from "../../images/stitchzone_logo_slogan_white.png";
import { ILoginRequestParams, LoginResponse } from "../../models";
import { authClient } from "../../ApiClients/AuthClient";
import { toast } from "@happeouikit/toast";
import { authCookieName, userDetails } from "../../constants";
interface LoginPageProps {}

interface LoginPageState {}

export class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  constructor(props: LoginPageProps) {
    super(props);
    this.state = {};
    if (sessionStorage.getItem("userDetails") != null) {
      window.location.href = "/";
    }
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin(params: ILoginRequestParams) {
    authClient
      .loginUser(params)
      .then((res: LoginResponse) => {
        console.log(res.data);
        if (res?.data?.token) {
          toast.success({ message: res.message });
          document.cookie = `${authCookieName}=${res.data.token};`;
          if (sessionStorage.getItem("userDetails") != null) {
            sessionStorage.removeItem("userDetails");
          }

          sessionStorage.setItem("userDetails", JSON.stringify(res.data.user));
          window.history.back();
        } else {
          toast.error({
            message: "Log in failed. Please try again",
            delay: 10000,
          });
        }
      })
      .catch((e) => {
        console.log("🚀 ~ file: SignUp.page.tsx:46 ~ handleSignUp ~ e", e);
        toast.error({ message: "Log in failed.", delay: 10000 });
      });
  }

  render() {
    return (
      <Section>
        <FormContainer>
          <Title>Welcome Back!</Title>
          <SubTitle>Log In to your account</SubTitle>
          <LoginForm handleLogin={this.handleLogin} />
          <Link href="/forget-password">Forgot Password?</Link>
          <SignUpCta>
            Don't have an account?{" "}
            <SignUpLink href="/sign-up">Sign Up</SignUpLink>
          </SignUpCta>
        </FormContainer>
      </Section>
    );
  }
}
