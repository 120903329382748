import React from "react";
import { Tooltip } from "@happeouikit/tooltip";
import { TextBeta, TextTheta } from "@happeouikit/typography";
import styled from "styled-components";
import {
  marginL,
  marginM,
  marginS,
  marginXL,
  marginXXL,
  paddingM,
  paddingS,
  primaryColor,
  tshirtColors,
} from "../../constants";
import { toast } from "@happeouikit/toast";
import { Dropdown } from "@happeouikit/form-elements";
import { IOneProduct, TshirtSizes } from "../../models";
import {
  IColorV2,
  ImagesV2,
  IOneProductV2,
} from "../../models/ApiResponses/IGetProductResponseV2";

interface ColorSizeAndQuantitySettingProps {
  onNext(data: Record<string, number>, colorObj: any, obj: any): void;
  onBack(): void;
  selectedSizes: any;
  selectedProduct: IOneProductV2;
  editCartProduct: any;
  selectedColorObj: IColorV2;
  onColorChange(imgs: IColorV2): void;
}

interface ColorSizeAndQuantitySettingState {
  selectedColor: { colorName: string; colorCode: string };
  selectedSizes: Record<string, number>;
}
export class ColorSizeAndQuantitySetting extends React.Component<
  ColorSizeAndQuantitySettingProps,
  ColorSizeAndQuantitySettingState
> {
  constructor(props: ColorSizeAndQuantitySettingProps) {
    super(props);
    this.state = {
      selectedColor: {
        colorName: props.selectedColorObj.colorName,
        colorCode: props.selectedColorObj.colorCode != ""?props.selectedColorObj.colorCode:props.selectedColorObj.value,
      },
      selectedSizes: props.selectedSizes || {
        // [TshirtSizes.S]: process.env.NODE_ENV === "development" ? 5 : 0,
        [TshirtSizes.S]: 0,
        [TshirtSizes.M]: 0,
        [TshirtSizes.L]: 0,
        [TshirtSizes.XL]: 0,
        [TshirtSizes.XXL]: 0,
        [TshirtSizes.XXXL]: 0,
        [TshirtSizes["4XL"]]: 0,
        [TshirtSizes["5XL"]]: 0,
      },
    };
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.validateAndNext = this.validateAndNext.bind(this);
    this.setPreferenceForEdit = this.setPreferenceForEdit.bind(this);
  }

  componentDidMount(): void {
    console.log("ColorSizeAndQuantitySetting - componentDidMount");
    if(this.props.editCartProduct){
      this.setPreferenceForEdit();
    }
  }

  setPreferenceForEdit(){
    const { selectedProduct, editCartProduct } = this.props;
    const selectedProductColor = selectedProduct.colors.find(
      (i: any) => i.value === editCartProduct.customizedProducts.colors.colorCode
    );
    console.log("selectedProductColor : ", selectedProductColor);
    if(selectedProductColor) {
      /* set color */
      this.handleColorChange(selectedProductColor);

      /* set size and quantity */
      const sizeList = selectedProductColor["size&quantity"];
      const selectedSizeListEditProduct = editCartProduct.customizedProducts.colors["size&quantity"];
      let selectedSizes:any = {};

      sizeList.map((sizeItm:{ sizeCode: string, sizeName: string, quantity: number, sizePrice: number }) => {
        selectedSizes[sizeItm.sizeCode] = 0;
      });

      selectedSizeListEditProduct.map((sizeItm:any) => {
        selectedSizes = { ...selectedSizes, [sizeItm.sizeCode]: sizeItm.quantity }
      });
      console.log("selectedSizes : ", selectedSizes);
      this.setState({ selectedSizes: selectedSizes });
    }
  }

  handleColorChange(color: IColorV2) {
    this.setState({
      selectedColor: {
        colorName: color.colorName,
        colorCode: color.colorCode != ""?color.colorCode:color.value,
      },
    });
    /* console.log({
      selectedColor: {
        colorName: color.colorName,
        colorCode: color.colorCode != ""?color.colorCode:color.value,
      },
    }); */
    this.props.onColorChange(color);
  }

  handleQuantityChange(size: string, quantityVal: string, _quantity: number) {
    console.log(`size : ${size}, quantityVal : ${quantityVal}, _quantity: ${_quantity}`);
    if (Number(quantityVal) <= 0) {
      const { selectedSizes } = this.state;
      this.setState({ selectedSizes: { ...selectedSizes, [size]: 0 } });
      return;
    }

    if (Number(quantityVal) > _quantity) {
      toast.error({
        message: `Available items ${_quantity}. Please select items less than ${_quantity}`,
        delay: 2000,
      });
      const { selectedSizes } = this.state;
      this.setState({ selectedSizes: { ...selectedSizes, [size]: 0 } });
    } else {
      const quantity = parseInt(quantityVal);
      const { selectedSizes } = this.state;
      this.setState({ selectedSizes: { ...selectedSizes, [size]: quantity } });
    }
  }

  calculatePrice(c: any, isSomeQuantitySelected: any) {
    const totalPrice = c.reduce(
      (acc: any, curr: any) => acc + curr.sizePrice * curr.quantity,
      0
    );
    return totalPrice;
  }

  validateAndNext() {
    const { selectedProduct } = this.props;
    const { price } = selectedProduct;
    const { selectedSizes } = this.state;
    const allQuantitySelection = Object.values(selectedSizes);
    const isSomeQuantitySelected = allQuantitySelection.reduce(
      (quantity1, quantity2) => quantity1 + quantity2
    );

    const selectedProductColor = this.props.selectedProduct.colors.find(
      (i: any) => i.value === this.state.selectedColor.colorCode
    );
    let colorObj = {};
    const obj = {
      price: 0,
      totalQuantity: 0,
    };

    /* console.log("isSomeQuantitySelected : ", isSomeQuantitySelected);
    console.log("selectedProductColor : ", selectedProductColor); */

    if (selectedProductColor) {
      const c = selectedProductColor["size&quantity"]
        .filter((i) => {
          return (
            selectedSizes[i["sizeCode"]] !== 0 && {
              ...i,
            }
          );
        })
        .map((i: any) => {
          return {
            sizeCode: i["sizeCode"],
            sizeName: i["sizeName"],
            sizePrice: i["sizePrice"],
            quantity: selectedSizes[i["sizeCode"]],
          };
        });

      colorObj = {
        ...selectedProductColor,
        "size&quantity": c,
      };
      obj.price =
        price * isSomeQuantitySelected +
        this.calculatePrice(c, isSomeQuantitySelected);
      obj.totalQuantity = isSomeQuantitySelected;
      /* console.log("size&quantity page total calculated price : ", obj); */
      // obj.extras.colorSizePrice = price * isSomeQuantitySelected;
    }

    if (isSomeQuantitySelected) {
      this.props.onNext(selectedSizes, colorObj, obj);
    } else {
      toast.error({ message: "Please add some quantity", delay: 2000 });
    }
  }

  render() {
    const { selectedProduct, selectedColorObj } = this.props;
    const { selectedColor, selectedSizes } = this.state;
    return (
      <>
        <MainContainer>
          <SettingHeader>
            <TextBeta className="bold">COLOR & QUANTITY</TextBeta>
            <Icon data-tip="Message from data-tip" data-for="tooltip3">
              i
            </Icon>
            <Tooltip id="tooltip3" place="bottom" />
          </SettingHeader>
          <p
            style={{
              fontSize: "18px",
              lineHeight: "22px",
              marginBottom: 0,
            }}
          >
            <b>{selectedProduct.name}</b>
          </p>
          
          <p
            style={{
              fontSize: "18px",
              lineHeight: "22px",
              marginBottom: "10px",
              marginTop: 0,
            }}
          >
            {selectedProduct.currency}
            <b>{selectedProduct.price} </b>
            (Base Price)
          </p>
          <TextTheta style={{
              fontSize: "14px",
              lineHeight: "22px",
              marginBottom: "5px",
              marginTop: 0,
            }}>
            <b>Description :</b> {selectedProduct.description}
          </TextTheta>
          <TextTheta style={{
              fontSize: "14px",
              lineHeight: "22px",
              marginBottom: "5px",
              marginTop: 0,
            }}>
            <b>Includes : </b> {selectedProduct.includes}
          </TextTheta>
          <TextTheta style={{
              fontSize: "14px",
              lineHeight: "22px",
              marginBottom: "5px",
              marginTop: 0,
            }}>
            <b>Selected Color : {selectedColor.colorName}</b>
          </TextTheta>
          <ColorsContainer>
            {selectedProduct.colors.map((currentColor) => {
              const { colorName, colorCode, value } = currentColor;
              return (
                <ColorBox
                  title={colorName}
                  selected={selectedColor.colorCode === value}
                  key={colorCode}
                  previewColor={value}
                  onClick={() => this.handleColorChange(currentColor)}
                />
              );
            })}
          </ColorsContainer>
          {selectedProduct.typeId.type !== "mask" && <SelectColorLabel>Select Quantity</SelectColorLabel>}
          <QuantitiySelectorContainer>
            {selectedColorObj["size&quantity"].map(
              ({ sizeCode, sizeName, quantity, sizePrice }) => {
                return (
                  <FormGroup key={sizeCode}>
                    {/* <StyledPrice>Qty: {quantity}</StyledPrice> */}
                    <SizeBtn sizeCode={sizeCode} >{sizeCode}</SizeBtn>
                    <QuantityInput
                      onChange={(e) =>
                        this.handleQuantityChange(
                          sizeCode,
                          e.target.value,
                          quantity
                        )
                      }
                      type="number"
                      min="0"
                      value={
                        // selectedSizes[sizeCode] = quantity
                        selectedSizes[sizeCode] === 0
                          ? ""
                          : selectedSizes[sizeCode]
                      }
                    />
                    {Number(sizePrice) !== 0 ? (
                      <StyledPrice>
                        +({selectedProduct.currency}
                        {sizePrice})
                      </StyledPrice>
                    ) : (
                      <StyledPrice color="grey">{""}</StyledPrice>
                    )}
                  </FormGroup>
                );
              }
            )}
          </QuantitiySelectorContainer>
        </MainContainer>
        <Divider></Divider>
        <CtaContainer>
          {/* <ClearStepBtn onClick={this.props.onBack}>Clear Step</ClearStepBtn> */}
          <NextpBtn onClick={this.validateAndNext}>Next</NextpBtn>
        </CtaContainer>
      </>
    );
  }
}

const MainContainer = styled.div`
  min-height: 500px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

const SettingHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${marginXL};
  p {
    margin-top: ${marginS};
  }
`;

const ColorsContainer = styled.div`
  margin-top: ${marginL};
`;

const ColorBox = styled.div<{ previewColor: string; selected: boolean }>`
  display: inline-block;
  margin-right: ${marginS};
  margin-bottom: ${marginS};
  background-color: ${(props) => props.previewColor};
  height: 25px;
  width: 25px;
  border-radius: 5px;
  border: ${(props) => (props.selected ? "2px solid #fff" : "none")};
  outline: ${(props) => (props.selected ? "2px solid #000" : "none")};
  cursor: pointer;
  box-shadow: #dddddd 1px 1px 8px 3px;
`;

const QuantitiySelectorContainer = styled.div`
  margin-top: ${marginL};
  display: flex;
`;

const SizeBtn = styled.button<{ bg?: string, sizeCode?:string }>`
  background-color: ${(props) => props.bg || "transparent"};
  border: none;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  flex: 1;
  padding: ${(props) => props.sizeCode === "QTY" ? "6px" : "10px"};
  margin-bottom: ${marginS};
`;

const QuantityInput = styled.input<{ bg?: string }>`
  background-color: ${(props) => props.bg || "transparent"};
  border: none;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  flex: 1;
  padding: 10px;
  text-align: center;
`;

const FormGroup = styled.div`
  width: 40px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: ${marginM};
`;

const SelectColorLabel = styled(TextTheta)`
  margin-top: ${marginXXL};
`;

const Divider = styled.div`
  height: 1px;
  background-color: #e3e3e3;
  margin: ${marginM} 0;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClearStepBtn = styled.button`
  margin-right: ${marginS};
  background: #dde3e6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
`;

const NextpBtn = styled.button`
  margin-right: ${marginS};
  background: ${primaryColor} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
  color: #ffffff;
`;

const Icon = styled.div`
font-style: italic;
font-size: 11px;
font-weight: bold;
margin: 0px 14px;
padding: 1px 7px;
border: 1px solid black;
border-radius: 50%;
}
`;

const StyledPrice = styled.span<{ color?: string }>`
  text-align: center;
  font-size: 0.7rem;
  color: ${(props) => props.color || "black"};
  flex: 1;
`;
