import { AnyAction } from "redux";
import { CLEAR_AUTH, SAVE_AUTH } from "../actions";
import { IAuthDetails } from "../models";

export function login(state: IAuthDetails | null = null, action: AnyAction) {
  switch (action.type) {
    case SAVE_AUTH: {
      localStorage.setItem("authDetails", JSON.stringify(action.data));
      return action.data;
    }

    case CLEAR_AUTH: {
      return null;
    }
    default:
      return state;
  }
}
