import { AnyAction } from "redux";
import {
  IProfileState,
  IProfileAddressState,
  IShippingRatesState,
} from "../models";

import {
  GET_PROFILE,
  UPDATE_PROFILE,
  GET_PROFILE_BILLING_ADDRESSES,
  GET_PROFILE_SHIPPING_ADDRESSES,
  GET_SHIPPING_RATES,
} from "../actions";

const initialStateProfile: IProfileState = {
  _id: "",
  name: "",
  email: "",
  phone: "",
  state: false,
  role: "",
  roleAssigned: [""],
};

interface IReduxProfileAddressState {
  billingAddresses: Array<IProfileAddressState>;
  shippingAddresses: Array<IProfileAddressState>;
}

const initialStateProfileAddresses: IReduxProfileAddressState = {
  billingAddresses: [],
  shippingAddresses: [],
};

const initialStateShippingRates: IShippingRatesState = {
  _id: "",
  lowerValueLimit: "",
  upperValueLimit: "",
  standard: "",
  priority: "",
  express: "",
  active: false,
};

export function profile(
  state: IProfileState = initialStateProfile,
  action: AnyAction
) {
  switch (action.type) {
    case GET_PROFILE:
      let profileData = action.data.data.user;
      //console.log("profileData --> : ", profileData);
      state = profileData;
      return { ...state };
    case UPDATE_PROFILE:
      //let profileData = action.data.data.user;
      //console.log("profileData --> : ", profileData);
      //state = profileData;
      return { ...state };
    default:
      return state;
  }
}

export function profileAddress(
  state: IReduxProfileAddressState = initialStateProfileAddresses,
  action: AnyAction
) {
  switch (action.type) {
    case GET_PROFILE_BILLING_ADDRESSES:
      let profileBillingData = action.data.data;
      //console.log("profileData --> : ", profileData);
      state.billingAddresses = profileBillingData;
      return { ...state };
    case GET_PROFILE_SHIPPING_ADDRESSES:
      //console.log("shippinh action.data : ", action.data.data);
      let profileShippingData = action.data.data;
      //console.log("profileData --> : ", profileData);
      state.shippingAddresses = profileShippingData;
      return { ...state };

    default:
      return state;
  }
}

export function shippingRates(
  state: IShippingRatesState = initialStateShippingRates,
  action: AnyAction
) {
  switch (action.type) {
    case GET_SHIPPING_RATES:
      let rateData = action.data.data[0];
      //console.log("profileData --> : ", profileData);
      state = rateData;
      return { ...state };
    default:
      return state;
  }
}
