import styled from "styled-components";
import { primaryColor, themeWhite } from "../../constants";

const AccordionCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  padding: 0 30px;
`;

const AccordionHeader = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? themeWhite : primaryColor)};
  background-color: ${(props) => (props.active ? primaryColor : themeWhite)};
  font-size: 24px;
  padding: 1px;

  & h5 {
    margin: 0;
    padding: 10px 0 10px 10px;
  }
`;

const AccordionCollapse = styled.div<{ collapse: boolean }>`
  position: relative;
  overflow: hidden;
  transition: height 0.35s ease;
  height: ${(props) => (props.collapse ? "auto" : "0")};
  font-size: 16px;
  line-height: 1.5;
  padding: ${(props) => (props.collapse ? "10px 0 10px 10px" : "0")};
`;

const Items = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  // margin: 0 20px 20px 20px;
  cursor: pointer;
`;

const Img = styled.img`
  width: 74px;
  height: 74px;
  object-fit: cover;
  box-shadow: #edededd4 0px 0px 4px 1px;
`;

const Desc = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0 10px 0;
  max-width: 200px;
  max-height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 110%;
  font-size: 90%;
  padding-top: 5px;
  width: 80px;
`;

const ColorHeader = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #142f8f;
`;

export {
  AccordionCard,
  AccordionHeader,
  AccordionCollapse,
  Container,
  Items,
  Item,
  Img,
  Desc,
  ColorHeader,
};
