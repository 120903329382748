import React from "react";
import styled from "styled-components";
import {
  FloaterIcon1,
  FloaterIcon2,
  FloaterIcon3,
  FloaterIcon4,
} from "../../components";

const Tab = ({ onChange, tab }) => {
  const handleClick = (index) => {
    onChange(index);
  };

  //   TODO: highlight active
  console.log(tab, "tabtab");
  return (
    <TabContainer>
      <ButtonLeft onClick={() => handleClick(1)} active={tab == 1}>
        <FloaterIcon1 />
      </ButtonLeft>
      <ButtonCenter onClick={() => handleClick(2)} active={tab == 2}>
        <FloaterIcon4 />
      </ButtonCenter>
      <ButtonRight onClick={() => handleClick(3)} active={tab == 3}>
        <FloaterIcon3 />
      </ButtonRight>
    </TabContainer>
  );
};

export default Tab;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 21px;
  opacity: 1;
`;

const ButtonLeft = styled.button`
  border-top-left-radius: 80px 80px;
  border-bottom-left-radius: 80px 80px;
  border: none;
  padding: 6px 10px 6px 10px;
  background: #142f8e;
  background: ${(props) => (props.active ? "#142f8e" : "transparent")};

  path {
    stroke: ${(props) => (props.active ? "#fff" : "rgba(66, 68, 71, 0.62);")};
  }
`;

const ButtonCenter = styled.button`
  border: none;
  padding: 6px 10px 6px 10px;
  background: ${(props) => (props.active ? "#142f8e" : "transparent")};

  path {
    stroke: ${(props) => (props.active ? "#fff" : "rgba(66, 68, 71, 0.62);")};
  }
`;

const ButtonRight = styled.button`
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
  border: none;
  padding: 6px 10px 6px 10px;
  background: ${(props) => (props.active ? "#142f8e" : "transparent")};

  path {
    stroke: ${(props) => (props.active ? "#fff" : "rgba(66, 68, 71, 0.62);")};
  }
`;
