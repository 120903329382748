import React from "react";
import { Promote } from "../../models";
import {
  Container,
  Col,
  Content,
  Title,
  Lists,
  Items,
  Link,
  Flex,
  Img,
} from "./styled.component";

import MensJacket from "../../images/MJacket.png";

interface GenderCategoryProps {
  promote: Promote[];
}

interface GenderCategoryState {
  selectedImg: Record<string, string>;
}

export class GenderCategory extends React.Component<
  GenderCategoryProps,
  GenderCategoryState
> {
  constructor(props: GenderCategoryProps) {
    super(props);
    this.state = {
      selectedImg: {},
    };
  }

  render() {
    const data = this.props.promote;
    let items: any = {};
    if (this.props.promote.length > 0) {
      let titles = data.map((e) => e.title);
      let uniqueTitles = Array.from(new Set(titles));
      uniqueTitles.forEach((e: string) => (items[e] = []));
      data.map((e) => items[e.title].push(e));
    }

    const { selectedImg } = this.state;

    return (
      <Container>
        <Col>
          {Object.keys(items).map((item) => (
            <Content key={item}>
              <Title>{item}</Title>
              <Flex>
                <Lists>
                  {items[item].map((i: any) => {
                    return (
                      <Items key={i.categoryId}>
                        <Link
                          href={`/catalog/${i.categoryId}`}
                          onMouseEnter={() =>
                            this.setState({
                              selectedImg: {
                                ...this.state.selectedImg,
                                [item]: i.image,
                              },
                            })
                          }
                        >
                          {i.categoryName}
                        </Link>
                      </Items>
                    );
                  })}
                </Lists>
                <Img src={selectedImg[item] || items[item][0].image} alt="" />
              </Flex>
            </Content>
          ))}
        </Col>
      </Container>
    );
  }
}
