import React from "react";
import { ImageModalContainer, ModalImage, CloseBtn } from "./styled.component";

interface ImageModalProps {
  URL: string;
  onClose(): any;
}
interface ImageModalState {}

export class ImageModal extends React.Component<
  ImageModalProps,
  ImageModalState
> {
  constructor(props: ImageModalProps) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    this.props.onClose();
  };
  render() {
    return (
      <ImageModalContainer>
        <CloseBtn title="Close Image" onClick={this.handleClick}>
          x
        </CloseBtn>
        <ModalImage src={this.props.URL} alt="image" />
      </ImageModalContainer>
    );
  }
}
