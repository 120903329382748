import styled from "styled-components";
import { primaryColor, themeWhite } from "../../constants";

const AccordionCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  padding: 0 30px;
`;

const AccordionHeader = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? themeWhite : primaryColor)};
  background-color: ${(props) => (props.active ? primaryColor : themeWhite)};
  font-size: 24px;
  padding: 1px;

  & h5 {
    margin: 0;
    padding: 10px 0 10px 10px;
  }
`;

const AccordionCollapse = styled.div<{ collapse: boolean }>`
  position: relative;
  overflow: hidden;
  transition: height 0.35s ease;
  height: ${(props) => (props.collapse ? "auto" : "0")};
  font-size: 16px;
  line-height: 1.5;
  padding: ${(props) => (props.collapse ? "10px 0 10px 10px" : "0")};
`;

export { AccordionCard, AccordionHeader, AccordionCollapse, Container };
