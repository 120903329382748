import styled from "styled-components";
import { primaryColor, themeWhite } from "../../constants";

interface TableProps {
  data: {
    id: number;
    orderValue: string;
    standard: string;
    priority: string;
    express: string;
  }[];
}

interface StyledTableRowProps {
  isEven: boolean;
}

const AccordionCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Container = styled.div`
  padding: 0 30px;
`;

const AccordionHeader = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? themeWhite : primaryColor)};
  background-color: ${(props) => (props.active ? primaryColor : themeWhite)};
  font-size: 24px;
  padding: 1px;

  & h5 {
    margin: 0;
    padding: 10px 0 10px 10px;
  }
`;

const AccordionCollapse = styled.div<{ collapse: boolean }>`
  position: relative;
  overflow: hidden;
  transition: height 0.35s ease;
  height: ${(props) => (props.collapse ? "auto" : "0")};
  font-size: 16px;
  line-height: 1.5;
  padding: ${(props) => (props.collapse ? "10px 0 10px 10px" : "0")};
`;

const Desc = styled.p`
  white-space: pre-line;
`;

const TableWrapper = styled.table`
  width: auto;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #142f8f;
  color: #fff;
  padding: 10px;
  text-align: left;
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
  white-space: pre-line;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Items = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  // margin: 0 20px 20px 20px;
  cursor: pointer;
`;

const Img = styled.img`
  width: 74px;
  height: 74px;
  object-fit: cover;
  box-shadow: #edededd4 0px 0px 4px 1px;
`;

const TermsHeader = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #142f8f;
`;

const StyledTableRow = styled.tr<StyledTableRowProps>`
  background-color: ${(props) => (props.isEven ? "#ffffff" : "#C3CAE2")};
`;

const Table: React.FC<TableProps> = ({ data }) => {
  return (
    <TableWrapper>
      <thead>
        <tr>
          <TableHeader>Order Value</TableHeader>
          <TableHeader>Standard 7-10 days</TableHeader>
          <TableHeader>Priority 2-5 days</TableHeader>
          <TableHeader>Express 1-3 days</TableHeader>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <StyledTableRow key={item.id} isEven={index % 2 === 0}>
            <TableCell>{item.orderValue}</TableCell>
            <TableCell>{item.standard}</TableCell>
            <TableCell>{item.priority}</TableCell>
            <TableCell>{item.express}</TableCell>
          </StyledTableRow>
        ))}
      </tbody>
    </TableWrapper>
  );
};

export {
  AccordionCard,
  AccordionHeader,
  AccordionCollapse,
  Container,
  Desc,
  Table,
  Items,
  Item,
  Img,
  TermsHeader,
};
