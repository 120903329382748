import { useEffect, useState } from "react";
import * as Tag from "./styled.component";
import { megaMenuData } from "../../models";

interface menuProps {
  menu: megaMenuData;
  id:any  ;
}

const MegaMenu: React.FC<menuProps> = ({ menu ,id}) => {
  const [selectedSubCategory, setSelectedSubCategory] = useState<any | null>(menu?.subcategories[0]);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(menu?.subcategories[0].products[0]);

  const handleSubCategoryHover = (subCategory: any) => {
    setSelectedSubCategory(subCategory);
    setSelectedProduct(subCategory.products[0]); // Select first product by default
  };

  const handleProductHover = (product: string) => {
    setSelectedProduct(product);
  };
useEffect(()=>{
  setSelectedSubCategory(menu?.subcategories[0]);
  setSelectedProduct(menu?.subcategories[0].products[0]);
},[menu])
  return (
    <>
      <Tag.Dropdown className='sub-category'>
        <Tag.Section width={20} >
          <h3>Sub Categories</h3>
          <Tag.LinkedList >
            {
              menu?.subcategories.map((subCategory) => (
                <Tag.ProductLinks href={`/catalog/${id}/subcategories`}>
                  <Tag.List
                key={subCategory.name}
                  onMouseEnter={() => handleSubCategoryHover(subCategory)}
                >
                  <Tag.CategoryContainer isSelected={selectedSubCategory.name === subCategory.name}>
                    <Tag.CategoryImage src={subCategory.image} alt='' />
                    <Tag.DetailContainer>
                      <Tag.Title>
                        {subCategory.name}
                      </Tag.Title>
                    </Tag.DetailContainer>
                  </Tag.CategoryContainer>
                </Tag.List>
                </Tag.ProductLinks>
              ))
            }
          </Tag.LinkedList>
        </Tag.Section>
        <Tag.Section width={30}>
          <h3>Products</h3>
          <Tag.LinkedList>
            {selectedSubCategory &&
              selectedSubCategory.products.map((product: any) => (
                <Tag.List
                  key={product}
                  onMouseEnter={() => handleProductHover(product)}
                >
                  <Tag.ProductLinks href={`/catalog/${id}/subcategories/${selectedSubCategory?._id}/products/${product?._id}/design`}> 
                  <Tag.DetailContainer>
                    <Tag.TitleProduct className="product-list" isSelected={selectedProduct.name === product.name}>
                      {selectedSubCategory ? `${product?.name}` : 'Select a product'}
                    </Tag.TitleProduct>
                  </Tag.DetailContainer>
                  </Tag.ProductLinks>
                </Tag.List>
              ))}
          </Tag.LinkedList>
        </Tag.Section>
        <Tag.Section>
          <Tag.ProductContainer>
          <Tag.ProductLinks href={`/catalog/${id}/subcategories/${selectedSubCategory?._id}/products/${selectedProduct?._id}/design`}> 
            <Tag.ProductImage src={`http://favorsbythebay.com/apis/${selectedProduct?.images?.front}`} />
            </Tag.ProductLinks>
          </Tag.ProductContainer>
          <h3>Product Details</h3>
          <Tag.ProductDetail>
            <Tag.DetailContainer>
            <Tag.ProductLinks href={`/catalog/${id}/subcategories/${selectedSubCategory?._id}/products/${selectedProduct?._id}/design`}> 
              <Tag.Title className="product-list">
                {selectedProduct ? `${selectedProduct?.name}` : 'Select a product'}
              </Tag.Title>
              </Tag.ProductLinks>
              <Tag.Title>
                {selectedProduct && selectedProduct?.includes ? `${selectedProduct?.includes}` : ''}
              </Tag.Title>
              <Tag.Title>
                {selectedProduct && selectedProduct?.description ? `${selectedProduct?.description}` : ''}
              </Tag.Title>
              <Tag.Price>
                {selectedProduct ? `${selectedProduct?.currency}${selectedProduct?.price.toString().includes(".")?selectedProduct?.price:`${selectedProduct?.price}.00`}` : ''}
              </Tag.Price>
            </Tag.DetailContainer>
          </Tag.ProductDetail>
        </Tag.Section>
      </Tag.Dropdown>
    </>
  );
};

export default MegaMenu;