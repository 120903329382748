import styled from "styled-components";

import Pattern from "../../images/pattern.png";
import { fontEven, primaryColor } from "../../constants";

const Container = styled.div`
  background-repeat: repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: contain;
  background-image: url(${Pattern});
  display: flex;
  padding: 100px 30px 0 30px;
  justify-content: center;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  text-align: left;
`;

const Title = styled.div`
  font-size: ${fontEven(10)};
  font-weight: bold;
  color: ${primaryColor};
  padding-bottom: 25px;
`;

const Lists = styled.ul`
  list-style: none;
  margin: 0 20px 0 0;
  padding: 0;
`;

const Items = styled.li`
  list-style: none;
  padding-bottom: 15px;
  transition: transform ease 300ms;
  :hover {
    transform: translate(-10px, 0);
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: "#424447";
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  width: 30vw;
  height: 45vh;
  object-fit: contain;
`;

export { Container, Col, Content, Title, Lists, Items, Link, Flex, Img };
