import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import styled from "styled-components";

import { TextBeta, TextTheta, TinyText } from "@happeouikit/typography";

import {
  MainContainer,
  Wrapper,
  SecondaryContainer,
  SettingHeader,
} from "./styled.component";

import { ProfileForm } from "./Profile.form";

interface ProfilePageProps {}

interface ProfilePageState {}

export class ProfilePage extends React.Component<
  ProfilePageProps,
  ProfilePageState
> {
  constructor(props: ProfilePageProps) {
    super(props);
  }

  componentDidMount(): void {}

  render(): React.ReactNode {
    return (
      <>
        <MainContainer>
          <Wrapper>
            <SettingHeader>
              <TextBeta className="bold">My Profile</TextBeta>
            </SettingHeader>
          </Wrapper>
        </MainContainer>
        <Wrapper>
          <SecondaryContainer>
            <ProfileForm />
          </SecondaryContainer>
        </Wrapper>
      </>
    );
  }
}
