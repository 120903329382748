import styled from "styled-components";
import {
  primaryColor,
  tertaryColor,
  fontOdd,
  fontM,
  secondaryColor,
  themeWhite,
} from "../../constants";

const Wapper = styled.div<{ themed: boolean }>`
  padding-top: 30px;
  background-color: ${(props) => (props.themed ? primaryColor : themeWhite)};
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  border-bottom: 1px solid gray;
`;

const Flex = styled.div<{ themed: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px 0;
  color: ${(props) => (props.themed ? themeWhite : secondaryColor)};
`;

const Logo = styled.img`
  margin-left: 30px;
  height: 40px;
  object-fit: cover;
  margin-bottom: 10px;
`;

const BrandTitle = styled.h5<{ themed: boolean }>`
  color: ${(props) => (props.themed ? themeWhite : primaryColor)};
  font-size: ${fontOdd(6)};
  text-transform: uppercase;
  margin: 0 0 30px 0;
`;

const BrandMessage = styled.p<{ themed: boolean }>`
  color: ${(props) => (props.themed ? themeWhite : tertaryColor)};
  font-size: ${fontOdd(7)};
  font-weight: 300;
  margin: 0px;
`;

const ColTitle = styled.h2<{ themed: boolean }>`
  color: ${(props) => (props.themed ? themeWhite : primaryColor)};
  font-weight: 600;
  font-size: ${fontM};
  margin: 0 0 30px 0;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const SocialList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  padding: 0 20px 15px 0;
`;
const Link = styled.a<{ themed: boolean }>`
  text-decoration: none;
  color: ${(props) => (props.themed ? themeWhite : secondaryColor)};
  font-size: ${fontOdd(7)};
`;

const ButtonAlpha = styled.button`
  text-align: left;
  color: #ffffff;
  background: ${secondaryColor} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 11px;
  cursor: pointer;
`;

export {
  Wapper,
  Container,
  Flex,
  Logo,
  BrandTitle,
  BrandMessage,
  ColTitle,
  List,
  SocialList,
  Item,
  Link,
  ButtonAlpha
};
