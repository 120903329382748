import React from "react";
import ReactDOM from "react-dom/client";
import WebFont from "webfontloader";
import App from "./App";
import { fontFamiliesToLoad } from "./constants";

WebFont.load({
  google: {
    families: fontFamiliesToLoad,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
