import React from "react";
import { OrderContainer } from "./OrderContainer";
import styled from "styled-components";

export const OrderPage = () => {
  return (
    <>
      <Box m="10">
        <OrderContainer />
      </Box>
    </>
  );
};

const Box = styled.div`
  display: grid;
  margin: ${(props) => props.m}px;
`;
