export enum Endpoints {
  "userSignup" = "user/signup",
  "userLogin" = "user/login",
  "userVerifymail" = "user/verifymail",
  "userForgotPassword" = "user/forgotpassword",
  "userResetPassword" = "user/resetpassword",
  "categoryAdd" = "category/Add",
  "categoryGet" = "public/category",
  "categoryDelete" = "category/Delete",
  "categoryUpdate" = "category/Update",
  "subcategoryAdd" = "subcategory/Add",
  "subcategoryGet" = "public/subcategory?categoryId={{id}}",
  "subcategoryDelete" = "subcategory/Delete",
  "subcategoryUpdate" = "subcategory/Update",
  "adminuserlist" = "admin/userlist",
  "lettersplacementAdd" = "lettersplacement/Add",
  "lettersplacementGet" = "lettersplacement/Get",
  "lettersplacementUpdate" = "lettersplacement/Update",
  "lettersplacementDelete" = "lettersplacement/Delete",
  "foregroundmaterialAdd" = "foregroundmaterial/Add",
  "foregroundmaterialGet" = "foregroundmaterial/get",
  "foregroundmaterialUpdate" = "foregroundmaterial/Update",
  "foregroundmaterialDelete" = "foregroundmaterial/Delete",
  "foregroundcolorAdd" = "foregroundcolor/Add",
  "foregroundcolorGet" = "foregroundcolor/Get",
  "foregroundcolorUpdate" = "foregroundcolor/Update",
  "foregroundcolorDelete" = "foregroundcolor/Delete",
  "backgroundmaterialAdd" = "backgroundmaterial/Add",
  "backgroundmaterialGet" = "backgroundmaterial/Get",
  "backgroundmaterialUpdate" = "backgroundmaterial/Update",
  "backgroundmaterialDelete" = "backgroundmaterial/Delete",
  "backgroundcolorAdd" = "backgroundcolor/Add",
  "backgroundcolorGet" = "backgroundcolor/Get",
  "backgroundcolorUpdate" = "backgroundcolor/Update",
  "backgroundcolorDelete" = "backgroundcolor/Delete",
  "fontstyleAdd" = "fontstyle/Add",
  "fontstyleGet" = "fontstyle/Get",
  "fontsizeGet" = "fontsize/Get",
  "fontstyleUpdate" = "fontstyle/Update",
  "fontstyleDelete" = "fontstyle/Delete",
  "threadtomatchAdd" = "threadtomatch/Add",
  "threadtomatchGet" = "threadtomatch/Get",
  "threadtomatchUpdate" = "threadtomatch/Update",
  "threadtomatchDelete" = "threadtomatch/Delete",
  "permissionGet" = "permission/Get",
  "permissionassign" = "permission/assign",
  "roleAdd" = "role/Add",
  "roleGet" = "role/Get",
  "roleUpdate" = "role/Update",
  "roleDelete" = "role/Delete",
  "permissionGetassigned" = "permission/Getassigned?roleId=63737ede40b5bc31ac144ccf",
  "migration" = "migration",
  "productAdd" = "product/Add",
  "productUpdate" = "product/Update",
  "productDelete" = "product/Delete",
  "productList" = "public/productlist",
  "productGet" = "public/oneProduct",
  "cartAdd" = "cart/Add",
  "cartGet" = "cart/Get",
  "cartDelete" = "cart/Delete",
  "cartUpdate" = "cart/Update",
  "appliques" = "public/appliques",
  "emboidaryOptions" = "public/embroidaries",
  "embroidaryLocationList" = "embroidaryLocationList/get",
  "emboidaryDesign" = "embroidarydesign/get",
  "embroidaryImageSize" = "public/imagesize",
  "megaMenu" = "/public/megaMenuData",
}
