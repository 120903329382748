import React, { Component } from 'react';
import SideMenuBar from './SideMenuBar';
import { sidebarMenu } from './StaticData.Constant';
import{ MainContentProps } from './MainContent';
import { MainLayoutSection } from './Styled.component';


interface mainLayoutProps {
    
  Content: React.ComponentType<MainContentProps>;
  heading: string; // Heading passed as a prop
  subHeadings: { title: string; content: string }[];
}


class mainLayout extends Component<mainLayoutProps> {
  render() {
    const { Content, heading, subHeadings } = this.props;
    return (
      <MainLayoutSection >
        <SideMenuBar menuItems={sidebarMenu}/>
        <Content heading={heading} subHeadings={subHeadings} />
      </MainLayoutSection>
    );
  }
}
export default mainLayout