import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { makePayment } from "../actions";
import { PaymentResponse } from "../models";

class PaymentClient extends BaseApiClient {
    public async makePayment(data: any) {
        try {
          return await this.postJson<any>("payment/creditcard/charge", data);
        } catch (error: any) {
            error = JSON.parse(error.metadata.apiErrorMessage);
            return error;
        }
    }

    public async paymentDetails(orderId: string){
        try {
            return await this.get<any>("payment/details?orderId="+orderId);
        } catch (error: any) {
            error = JSON.parse(error.metadata.apiErrorMessage);
            return error;
        }
    }
}

export const paymentClient = new PaymentClient();
