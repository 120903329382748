import React from "react";
import Accordion from "./Accordion";
import {
  Container,
  Desc,
  Table,
  Items,
  Item,
  Img,
  LetterSizeHeader,
  EmbroideryImg,
} from "./styled.component";
import CenterOfBackImage from "../../images/center_of_back.jpg";
import { connect } from "react-redux";
import { spectificationClient } from "../../ApiClients";
import { IAppState, SpecificationResponseDatum } from "../../models";
import { Dispatch } from "redux";

interface SizeAndLocationPageProps {
  getSpecification(): void;
  specification: any | SpecificationResponseDatum;
}

interface SizeAndLocationPageState {
  active: number | null;
  isExpanded: boolean;
}

const data = [
  {
    id: 1,
    size: ".25 inch tall letters",
    cost: "$5.00 for the first 20 letters \n $0.15 for each additional letter",
    image: "",
  },
  {
    id: 2,
    size: ".5 inch tall letters",
    cost: "$5.00 for the first 15 letters \n $0.25 for each additional letter",
    image: "",
  },
  {
    id: 3,
    size: ".75 inch tall letters",
    cost: "$5.00 for the first 10 letters \n $0.35 for each additional letter",
    image: "",
  },
  {
    id: 4,
    size: "1 inch tall letters",
    cost: "$5.00 for the first 5 letters \n $0.45 for each additional letter",
    image: "",
  },
  {
    id: 5,
    size: "1.25 inch tall letters",
    cost: "$5.00 for the location \n $0.55 for each additional letter",
    image: "",
  },
  {
    id: 6,
    size: "1.5 inch tall letters",
    cost: "$5.00 for the location \n $0.65 for each additional letter",
    image: "",
  },
  {
    id: 7,
    size: "1.75 inch tall letters",
    cost: "$5.00 for the location \n $0.75 for each additional letter",
    image: "",
  },
  {
    id: 8,
    size: "2 inch tall letters",
    cost: "$5.00 for the location \n $1.00 for each additional letter",
    image: "",
  },
  {
    id: 9,
    size: "2.25 inch tall letters",
    cost: "$5.00 for the location \n $1.25 for each additional letter",
    image: "",
  },
  {
    id: 10,
    size: "2.5 inch tall letters",
    cost: "$5.00 for the location \n $1.50 for each additional letter",
    image: "",
  },
  {
    id: 11,
    size: "8 inch tall numbers",
    cost: "$8.00 for the location \n Applique Style: Foreground and Background \n Font Style: Varsity",
    image: CenterOfBackImage,
  },
];

export class SizeAndLocationPageComponent extends React.Component<
  SizeAndLocationPageProps,
  SizeAndLocationPageState
> {
  constructor(props: SizeAndLocationPageProps) {
    super(props);
    this.state = {
      active: null,
      isExpanded: true,
    };
  }

  componentDidMount(): void {
    this.props.getSpecification();
  }

  handleAccordion = (id: number, isExpanded: boolean) => {
    this.setState({ active: id, isExpanded: isExpanded });
  };
  render() {
    let { embroidaryLocation } = this.props.specification.specification;
    if(embroidaryLocation) {
      embroidaryLocation = embroidaryLocation.sort((a: any, b: any) => a.priority - b.priority);
    }

    return (
      <Container>
        <LetterSizeHeader>Letter Size and Locations</LetterSizeHeader>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Letter Sizes"
          id={1}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Desc>
            Number of letters will vary with location of embroidery. Not all
            locations can be embroidered on.
          </Desc>
          <Table data={data} />
        </Accordion>
        <Accordion
          handleToggle={this.handleAccordion}
          header="Locations"
          id={2}
          active={this.state.active}
          isExpanded={this.state.isExpanded}
        >
          <Items>
            {embroidaryLocation?.map((col: any) => {
              return (
                <Item>
                  <EmbroideryImg src={col.image} />
                  <Desc>{col.name}</Desc>
                </Item>
              );
            })}
          </Items>
        </Accordion>
      </Container>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    specification: state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getSpecification: () => spectificationClient.getSpecification(dispatch),
  };
};

export const SizeAndLocationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SizeAndLocationPageComponent);
