import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { getHeader } from "../actions";
import { HeaderResponse } from "../models";

class FooterClient extends BaseApiClient {
    public async postSubscriber(data: any, dispatch: Dispatch) {
        try {
            const subscriberData = await this.postFormData<any>(
              "public/sendMail",
              this.generateFormData(data)
            );

            console.log("after update subscriberData : ", subscriberData);
            
            return subscriberData;
          } catch (error: any) {
            console.log("error : ", error.metadata);
            error = JSON.parse(error.metadata.apiErrorMessage);
            console.log("error : ", error);
            return error;
          }
    }
}

export const footerClient = new FooterClient();
