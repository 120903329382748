import React, { Component } from 'react';
import * as Style from './Styled.component';
import ShippingRatesTable from './ShippingRatesTable';
import { FundraiseForm } from '../Fundraise/Funraise.form';
import { ContactForm } from '../Contact/Contact.form';

export interface MainContentProps {
  heading: string;
  subHeadings: { title: string; content: string; color?: string }[];
}

class MainContent extends Component<MainContentProps> {
  render() {
    const { heading, subHeadings } = this.props;

    return (
      <Style.PageContainer>
        {/* Main Heading */}
        <Style.MainHeading>{heading}</Style.MainHeading>

        {/* Sub-headings and Paragraphs */}
        <Style.SectionContainer>
          {subHeadings.map((subHeading, index) => (
            subHeading.title.length>1?
              <Style.Section key={index}>
              <Style.SubHeading>{subHeading.title}</Style.SubHeading>
              {subHeadings[index+1]?.title?.length>1?
               <Style.Paragraph color={subHeading.color}>{subHeading.content}</Style.Paragraph>
              :
              <Style.List color={subHeading.color}>{subHeading.content}</Style.List>
              }
              </Style.Section>
              :
              <Style.List color={subHeading.color}>{subHeading.content}</Style.List>
          ))}
        </Style.SectionContainer>
        {heading === "SHIPPING & PICK-UP"?<ShippingRatesTable/> :''}
        {heading === "FUNDRAISE"?<FundraiseForm/> :''}
        {heading === "CONTACT US"?<ContactForm/> :''}
      </Style.PageContainer>
    );
  }
}
export default MainContent