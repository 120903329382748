import React from "react";
import styled from "styled-components";

export const Accordion: React.FC<{
  isOpen: boolean;
  children?: Array<React.ReactNode>;
}> = ({ isOpen, children }) => (
  <Contianer>
    {children && (
      <>
        <Header isOpen={isOpen}>{children[0]}</Header>
        {isOpen && children[1]}
      </>
    )}
  </Contianer>
);

const Contianer = styled.div``;

const Header = styled.div<{ isOpen: boolean }>`
  background-color: ${(props) => (props.isOpen ? "#ECECEC" : "unset")};
`;
