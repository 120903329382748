import React from "react";
export const FloaterIcon1 = () => (
  <svg id="Color_Quantity" data-tip data-for="Color_Quantity_tooltip" data-name="Color &amp; Quantity" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <rect id="Rectangle_121" data-name="Rectangle 121" width="36" height="36" fill="none" />
    <g id="Group_58" data-name="Group 58" transform="translate(2.45 2.45)">
      <path id="Path_91" data-name="Path 91" d="M23.563,0H8.527C3.285,0,0,3.71,0,8.961V23.128C0,28.38,3.27,32.09,8.527,32.09H23.563c5.257,0,8.527-3.71,8.527-8.961V8.961C32.09,3.71,28.82,0,23.563,0Z" transform="translate(-0.45 -0.45)" fill="none" stroke="rgba(66,68,71,0.62)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" fill-rule="evenodd" />
      <path id="Union_1" data-name="Union 1" d="M7.518,0V0Zm7.4,3.661v0ZM0,3.661v0Z" transform="translate(8.432 9.63)" fill="none" stroke="rgba(66,68,71,0.62)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" />
      <path id="Path_93" data-name="Path 93" d="M7.251,21.546H21.968" transform="translate(1.181 -0.705)" fill="none" stroke="rgba(66,68,71,0.62)" stroke-linecap="round" stroke-width="2.5" />
    </g>
  </svg>

);
