export interface IColorSelection {
  //   colorValue: string;
  //   quantityAndSize: Array<IQuantityAndSize>;
  [colorName: string]: Array<IQuantityAndSize>;
}

export interface IQuantityAndSize {
  size: TshirtSizes;
  quantity: number;
}

export enum TshirtSizes {
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
  XXL = "2XL",
  XXXL = "3XL",
  '4XL'= '4XL',
  '5XL' = '5XL'
}
