import { Dispatch } from "redux";
import WebFont from "webfontloader";
import { updateAppliqueList, updateAppliqueOption } from "../actions";
import { Endpoints } from "../constants";
import {
  IBackgroundColorResponse,
  IBackgroundMaterialResponse,
  ICustomizationOptions,
  IForegroundColorResponse,
  IGetBackgroundColorRequest,
  IGetForegroundColorRequest,
  ILettersPlacementResponse,
  IForegroundMaterialResponse,
  IFontStyleResposne,
  IThreadToMatchResposne,
  IAppliqueResponse,
  IFontStyle,
} from "../models";
import { BaseApiClient } from "./BaseApiClient";

class AppliqueClient extends BaseApiClient {
  async getLetterPlacement() {
    const url = Endpoints.lettersplacementGet;
    const response = await this.get<ILettersPlacementResponse>(url);
    return response.data;
  }

  getForegroundMaterials() {
    const url = Endpoints.foregroundmaterialGet;
    return this.get<IForegroundMaterialResponse>(url);
  }

  getBackgroundMaterials() {
    const url = Endpoints.backgroundmaterialGet;
    return this.get<IBackgroundMaterialResponse>(url);
  }

  getForeGroundColorForMaterial({
    foregroundMaterialId,
  }: IGetForegroundColorRequest) {
    const url = this.constructUrl(Endpoints.foregroundcolorGet, {
      foregroundMaterialId,
    });
    return this.get<IForegroundColorResponse>(url);
  }

  getBackgroundColorForMaterial({
    backgroundMaterialId,
  }: IGetBackgroundColorRequest) {
    const url = this.constructUrl(Endpoints.backgroundcolorGet, {
      backgroundMaterialId,
    });
    return this.get<IBackgroundColorResponse>(url);
  }

  async getAllForegroundCustomizationOptions(): Promise<ICustomizationOptions> {
    const foregroudMaterials = await this.getForegroundMaterials();
    let result: ICustomizationOptions = {};

    for (const element of foregroudMaterials.data) {
      const { _id, name, currency, price, value } = element;
      const colorsForCurrentMaterial = await this.getForeGroundColorForMaterial(
        { foregroundMaterialId: _id }
      );

      result[name] = {
        label: name,
        value: _id,
        currency,
        price,
        value1: value,
        availableFills: colorsForCurrentMaterial.data.map(
          ({ name, value, pattern, currency, price }) => ({
            label: name,
            value: value ? value : pattern,
            currency,
            price,
          })
        ),
      };
    }

    return result;
  }

  async getAllBackgroundCustomizationOptions(): Promise<ICustomizationOptions> {
    const backgroundMaterial = await this.getBackgroundMaterials();
    let result: ICustomizationOptions = {};
    for (const element of backgroundMaterial.data) {
      const { _id, name, currency, price, value } = element;
      const colorsForCurrentMaterial = await this.getBackgroundColorForMaterial(
        { backgroundMaterialId: _id }
      );

      result[name] = {
        label: name,
        value: _id,
        currency,
        price,
        value1: value,
        availableFills: colorsForCurrentMaterial.data.map(
          ({ name, value, currency, price, pattern }) => ({
            label: name,
            value: value ? value : pattern,
            currency,
            price,
          })
        ),
      };
    }

    return result;
  }

  async getFontStyleOptions() {
    const url = Endpoints.fontstyleGet;
    const resposne = await this.get<IFontStyleResposne>(url);
    if (resposne.data.length) {
      resposne.data.forEach((font) => {
        WebFont.load({
          // active: () => resolve(resposne.data),
          custom: {
            families: [font.name],
            urls: [font.value],
          },
        });
      });
    }
    return resposne.data;
  }

  async getFontSizeOptions() {
    const url = Endpoints.fontsizeGet;
    const resposne = await this.get<IFontStyleResposne>(url);
    return resposne.data;
  }

  async getThreadOptions() {
    const url = Endpoints.threadtomatchGet;
    const response = await this.get<IThreadToMatchResposne>(url);
    return response.data.map((res) => {
      return {
        ...res,
        label: res.name,
      };
    });
  }

  async fetchAllOptions(dispatch: Dispatch) {
    const data = await Promise.all([
      this.getAllForegroundCustomizationOptions(),
      this.getAllBackgroundCustomizationOptions(),
      // this.getLetterPlacement(),
      this.getFontStyleOptions(),
      this.getThreadOptions(),
      // this.getFontSizeOptions(),
      // this.getApliques(),
    ]);
    const objToSave = {
      availableForegroundOptions: data[0],
      availableBackgroundOptions: data[1],
      fontStyleOptions: data[2],
      isCustomizationOptionsLoaded: true,
      availableThreadOptions: data[3],
      // fontSizeOptions: data[4],
    };
    dispatch(updateAppliqueOption(objToSave));
    // dispatch(updateAppliqueList(data[5]));
  }

  async getApliques() {
    const response = await this.get<IAppliqueResponse>(Endpoints.appliques);
    return response.data;
  }
}

export const appliqueClient = new AppliqueClient();
