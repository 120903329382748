import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { getAnnouncement } from "../actions";
import { AnnouncementResponse } from "../models";

class AnnouncementClient extends BaseApiClient {
  public async getAnnouncement(dispatch: Dispatch) {
    try {
      const announcementData = await this.get<AnnouncementResponse>(
        "public/notice"
      );
      dispatch(getAnnouncement(announcementData));
      return announcementData;
    } catch (error) {
      console.log(error);
    }
  }
}

export const announcementClient = new AnnouncementClient();
