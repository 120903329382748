import { IOneProductV2 } from "../models/ApiResponses/IGetProductResponseV2";

export const SELECTED_PRODUCT = "SELECTED_PRODUCT";

export function updateProduct(data: IOneProductV2) {
  return {
    type: SELECTED_PRODUCT,
    data,
  };
}
