import React from "react";

// import { useSelector } from "react-redux";
// import { IAppState, IFooterState } from "../../models";
import { Flex, Link } from "./styled.component";

interface FooterProps {
  isActive: boolean;
}

export const Copyright: React.FC<FooterProps> = ({ isActive }) => {
  return (
    <Flex themed={isActive}>
      Copyright © StitchZone. All rights reserved. General Inquiries:
      510-818-1182 • 510-818-1110 •&nbsp;
      <Link themed={isActive} href="mailto:info@stitchzone.com">
        info@stitchzone.com
      </Link>
    </Flex>
  );
};
