import { AnyAction } from "redux";
import {
  SELECT_APPLIQUE,
  UPDATE_APPLIQUE_LIST,
  UPDATE_APPLIQUE_OPTION,
  UPDATE_APPLIQUE_VIEW,
} from "../actions";
import {
  GREEK_ALPHABETS,
  IAppliqueData,
  ICustomizationOptions,
  IFontStyle,
  ILetterPlacement,
  IThreadToMatch,
  NUMBERS,
} from "../models";
import {
  ALPHABET_TYPES,
  AppliqueView,
} from "../widgets/shirtDesigner/Applique";

export interface IAppliqueOptions {
  availableForegroundOptions: ICustomizationOptions;
  availableBackgroundOptions: ICustomizationOptions;
  fontStyleOptions: Array<IFontStyle>;
  isCustomizationOptionsLoaded: boolean;
  availableThreadOptions: Array<IThreadToMatch>;
}

export interface IAppliqueReducer {
  appliques: Array<IAppliqueData>;
  appliqueOptions: IAppliqueOptions;
  selectedApplique?: IAppliqueData;
  appliqueView: AppliqueView;
}

export const initialState: IAppliqueReducer = {
  appliqueOptions: {
    availableForegroundOptions: {},
    availableBackgroundOptions: {},
    fontStyleOptions: [],
    availableThreadOptions: [],
    isCustomizationOptionsLoaded: false,
  },
  appliques: [],
  appliqueView: {
    front: {
      alphabetType: ALPHABET_TYPES.GREEK,
      letterSize: { _id: "", size: "" },
      stitchingType: { _id: "", stitchingType: "", price: 0, currency: "" },
      letterPlacement: {},
      specialInstructions: "",
      selectedGreekText: [
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
      ],
      sashSettings: {
        fill: "",
        stroke: "",
        material: "",
      },
      foreGroundSettings: {
        fill: "",
        stroke: "",
        material: "",
      },
      backgroundSettings: {
        fill: "",
        stroke: "",
        material: "",
      },
      enteredText: "",
      fontFamilyName: {},
      heatSealedLetter: {
        isactive: false,
        cost: "0",
      },
      customFiles: [],
    },
    back: {
      alphabetType: ALPHABET_TYPES.GREEK,
      letterSize: { _id: "", size: "" },
      stitchingType: { _id: "", stitchingType: "", price: 0, currency: "" },
      letterPlacement: {},
      specialInstructions: "",
      isCenterApplique: true,
      heatSealedLetter: {
        isactive: false,
        cost: "0",
      },
      greekorNumber: "greek",
      selectedGreekText: [
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
        GREEK_ALPHABETS["Select Letter"],
      ],
      selectedNumber: [
        NUMBERS["Select Numbers"],
        NUMBERS["Select Numbers"],
        NUMBERS["Select Numbers"],
      ],
      foreGroundSettings: {
        fill: "",
        stroke: "",
        material: "",
      },
      backgroundSettings: {
        fill: "",
        stroke: "",
        material: "",
      },
      enteredText: "",
      fontFamilyName: {},
      customFiles: [],
    },
  },
};

export function applique(
  state: IAppliqueReducer = initialState,
  action: AnyAction
): IAppliqueReducer {
  switch (action.type) {
    case UPDATE_APPLIQUE_OPTION:
      return { ...state, appliqueOptions: action.data };
    case UPDATE_APPLIQUE_LIST:
      return { ...state, appliques: action.data };
    case SELECT_APPLIQUE:
      return { ...state, selectedApplique: action.data };
    case UPDATE_APPLIQUE_VIEW:
      return {
        ...state,
        appliqueView: action.appliqueView,
      };
    default:
      return state;
  }
}
