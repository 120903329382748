import React from "react";

import {
  StyledInput,
  StyledButton,
  ButtonContainer,
  StyledTextarea,
  StyeledCheckbox,
  StyledSignup,
} from "./styled.component";

interface FundraiseFormProps {}

export const FundraiseForm: React.FC<FundraiseFormProps> = () => {
  return (
    <>
      <StyledSignup> Sign Up</StyledSignup>
      <p> Please fill out the following information </p>
      <p style={{color: "red"}}> * indicates required fields</p>
      <StyledInput
        type="text"
        placeholder="Fund Raise Start Date *"
        autoComplete="off"
      />
      <StyledInput
        type="text"
        placeholder="Organization *"
        autoComplete="off"
      />
      <StyledInput type="text" placeholder="School *" autoComplete="off" />

      <StyledInput
        type="text"
        placeholder="Contact Name *"
        autoComplete="off"
      />

      <StyledInput
        type="text"
        placeholder="Address Line 1 *"
        autoComplete="off"
      />

      <StyledInput
        type="text"
        placeholder="Address Line 2 *"
        autoComplete="off"
      />

      <StyledInput type="text" placeholder="City *" autoComplete="off" />

      <StyledInput
        type="text"
        placeholder="State/Province/Region *"
        autoComplete="off"
      />

      <StyledInput type="text" placeholder="Zip *" autoComplete="off" />

      <StyledInput
        type="text"
        placeholder="Phone Number *"
        autoComplete="off"
      />

      <StyledInput type="text" placeholder="Email *" autoComplete="off" />

      <StyeledCheckbox label="Join StitchZone's mailing list" defaultChecked />
      <br></br>
      <StyledTextarea
        rows={5}
        cols={86} 
        placeholder="Comments" />

      <ButtonContainer>
        <StyledButton>Submit</StyledButton>
        <StyledButton>Clear</StyledButton>
      </ButtonContainer>
      <div style={{marginBottom: "25px"}} />
    </>
  );
};
