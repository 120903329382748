import styled from "styled-components";
import Pattern from "../../images/pattern.png";

const SliderContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  right: -100px;
  transform: translate(-15%, -50%);
  align-items: flex-start;
  width: 35%;
`;

const HeadTag = styled.h1`
  color: blue;
  font-size: 60px;
  margin: 0%;
  width: 80%;
`;
const Btn = styled.a`
  text-decoration: none;
  display: inline-block;
  color: black;
  padding: 10px;
  margin: 1px;
  font-size: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
const PTag = styled.p`
  width: 60%;
`;

const BtnContainer = styled.div`
  border: 2px solid blue;
  width: fit-content;
  color: blue;
  font-weight: bold;
  font-size: 20px;
`;

const ContainerBox = styled.div`
  background-repeat: repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: contain;
  background-image: url(${Pattern});
  padding: 100px;
`;
const ContainerTry = styled.div`
  height: 14vh;
  background-color: white;
  color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px rgb(14, 16, 133);
  display: flex;
  justify-content: space-between;
  transition: transform ease 300ms;
  :hover {
    box-shadow: 2px 2px 2px 2px rgb(14, 16, 133);
  }
`;

const SignupBtn = styled.button`
  height: 6vh;
  border-radius: 25px;
  border-color: darkblue;
  background-color: darkblue;
  color: white;
`;

const SignUpBtnContainer = styled.div`
  padding-top: 30px;
`;

const TextContainer = styled.div`
  padding-top: 10px;
`;

const SignUpP = styled.p`
  font-size: 14px;
  color: black;
`;
const SpanTag = styled.span`
  color: darkblue;
  font-weight: bold;
`;

export {
  Container,
  HeadTag,
  Btn,
  BtnContainer,
  ContainerTry,
  SignupBtn,
  TextContainer,
  SignUpBtnContainer,
  PTag,
  SliderContainer,
  SignUpP,
  SpanTag,
  ContainerBox,
};
