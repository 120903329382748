import React from "react";
import { Title } from "../../components/Title";
import { Container, FormContainer } from "./styled.component";
import { ContactForm } from "./Contact.form";

export class ContactPage extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Title>Contact</Title>
        <Container>
          <p>If you have any questions or comments, please submit below.</p>
          <FormContainer>
            <ContactForm />
          </FormContainer>
        </Container>
      </>
    );
  }
}
