import { AnyAction } from "redux";
import { GET_ANNOUNCEMENT } from "../actions";
import { AnnouncementResponseDatum } from "../models";


let initialState: AnnouncementResponseDatum = {
    _id: '',
    file: '',
    isVisible: false,
    createdAt: '',
    updatedAt: '',
    __v: 0
  };

export function announcement(state: AnnouncementResponseDatum = initialState, action: AnyAction){
 switch(action.type){
    case GET_ANNOUNCEMENT:
        initialState = action.data.data
        return { ...initialState, isFetching: false };
    default:
        return state
 }
}