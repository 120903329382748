import React, { Dispatch } from "react";
import { toast } from "@happeouikit/toast";
import {
  Section,
  Logo,
  Desc,
  FormContainer,
  Title,
  SubTitle,
  SignUpCta,
  SignUpLink,
} from "./styled.component";
import { SignUpForm } from "./SignUp.form";
import LogoImg from "../../images/Logo.png";
import {
  IAppState,
  IAuthDetails,
  ISignUpRequestParams,
  ISignupResponse,
} from "../../models";
import { authClient } from "../../ApiClients/AuthClient";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveAuthDetails } from "../../actions";

interface SignUpPageProps {}

interface SignUpPageState {}

export class SignUpPageComponent extends React.Component<
  SignUpPageProps & any,
  SignUpPageState
> {
  constructor(props: SignUpPageProps) {
    super(props);
    this.state = {};
    this.handleSignUp = this.handleSignUp.bind(this);
  }

  handleSignUp(params: ISignUpRequestParams) {
    params.phone = params.phone.replace(/-/g, "");
    authClient
      .signup(params)
      .then((res: ISignupResponse) => {
        this.props.saveAuthDetails(res.data);
        toast.message({
          title: "Login Success",
          description: "To login, click here!",
          actionLabel: "Login",
          actionHandler: () => {
            window.location.pathname = "/login";
          },
          delay: 10000,
        });
      })
      .catch((e) => {
        console.log("🚀 ~ file: SignUp.page.tsx:46 ~ handleSignUp ~ e", e);
        toast.error({ message: "Sign up failed.", delay: 10000 });
      });
  }

  render() {
    return (
      <Section>
        <FormContainer>
          <Title>Welcome to Stitchzone!</Title>
          <SubTitle>Sign Up to start your design</SubTitle>
          <SignUpForm handleSignUp={this.handleSignUp} />
          <SignUpCta>
            Already have an account?{" "}
            <SignUpLink href="/login">Log in</SignUpLink>
          </SignUpCta>
        </FormContainer>
      </Section>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    authDetails: state.login,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    saveAuthDetails: (auth: IAuthDetails) => dispatch(saveAuthDetails(auth)),
  };
};

export const SignUpPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpPageComponent);
