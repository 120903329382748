import styled from "styled-components";

const Container = styled.div`
  padding: 0 30px;
`;

const Frame = styled.iframe`
  width: 100%;
  height: 85vh;
`;

const Breadcrum = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 20px;
`;

const Crums = styled.li`
  margin-right: 10px;

  a {
    text-decoration: none;
  }
`;

const Items = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  // margin: 0 20px 20px 20px;
  cursor: pointer;
`;

const Img = styled.img`
  width: 250px;
  height: 350px;
  object-fit: cover;
  box-shadow: #edededd4 0px 0px 4px 1px;
`;

const Desc = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0 10px 0;
  /* max-width: 200px; */
  max-height: 45px;
  /* white-space: nowrap; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchContainer = styled.div`
  margin-right: 15px;
  display: flex;
`;

const SearchInput = styled.input`
  border: none;
  padding: 4px 10px;
  border-radius: 24px;
  border: 1px solid #80808080;
`;




export {
  Container,
  Frame,
  Breadcrum,
  Crums,
  Items,
  Item,
  Img,
  Desc,
  Flex,
  SearchContainer,
  SearchInput,
 
};
