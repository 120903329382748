import { AnyAction } from "redux";
import { ICategoryState } from "../models";
import { GET_CATEGORY, GET_SUB_CATEGORY, GET_PRODUCT_LIST } from "../actions";

const initialState: ICategoryState = {
  list: [],
  subcategories: [],
  productList: [],
};

export function categories(
  state: ICategoryState = initialState,
  action: AnyAction
): ICategoryState {
  switch (action.type) {
    case GET_CATEGORY:
      let list = action.data.data;
      state.list = list;
      return { ...state };
    case GET_SUB_CATEGORY:
      return { ...state, subcategories: action.data };
    case GET_PRODUCT_LIST:
      return { ...state, productList: action.data };
    default:
      return state;
  }
}
