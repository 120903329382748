import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { saveAuthDetails } from "./actions";
import { authCookieName } from "./constants";
import { IAppState, IAuthDetails } from "./models";
import { AuthUtils } from "./Utils";
import { Loader } from "@happeouikit/loaders";
import styled from "styled-components";

interface LoginBoundaryProps {
  routesAvailableForLoggedInUsers: React.ReactNode;
  routesAvailableForNonLoggedInUsers: React.ReactNode;
}

interface LoginBoundaryState {
  isLoggedIn: boolean;
}

interface ReduxProps {
  authDetails: IAuthDetails | null;
  loaderCount: number;
  saveAuthDetails(auth: IAuthDetails): void;
}

const LoginContext = React.createContext<{
  isLoggedIn: boolean;
  changeLoginStatus(status: boolean): void;
}>({ isLoggedIn: false, changeLoginStatus(status) {} });

export class LoginBoundaryComponent extends React.Component<
  LoginBoundaryProps & ReduxProps,
  LoginBoundaryState
> {
  constructor(props: LoginBoundaryProps & ReduxProps) {
    super(props);
    // change the value of this boolean to mimic required state
    this.state = {
      isLoggedIn: false,
    };
    this.changeLoginStatus = this.changeLoginStatus.bind(this);
  }

  componentDidMount(): void {
    const parsedCookies = AuthUtils.parseCookies();
    if (parsedCookies[authCookieName]) {
      this.changeLoginStatus(true);
      // do the user fetch and saving here
    }
  }

  private changeLoginStatus(status: boolean) {
    this.setState({ isLoggedIn: status });
  }

  render(): React.ReactNode {
    const {
      routesAvailableForLoggedInUsers,
      routesAvailableForNonLoggedInUsers,
      loaderCount,
    } = this.props;
    const { isLoggedIn } = this.state;
    return (
      <LoginContext.Provider
        value={{ isLoggedIn, changeLoginStatus: this.changeLoginStatus }}
      >
        {loaderCount > 0 && (
          <LoaderOverlay>
            <LoaderContainer>
              <Loader size="large"></Loader>
            </LoaderContainer>
          </LoaderOverlay>
        )}
        {routesAvailableForLoggedInUsers}
        {/* {isLoggedIn && routesAvailableForLoggedInUsers}
        {/* {isLoggedIn && routesAvailableForLoggedInUsers}
        {!isLoggedIn && routesAvailableForNonLoggedInUsers} */}
      </LoginContext.Provider>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    authDetails: state.login,
    loaderCount: state.loader.loaderCount,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    saveAuthDetails: (auth: IAuthDetails) => dispatch(saveAuthDetails(auth)),
  };
};

export const LoginBoundary = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginBoundaryComponent);

const LoaderContainer = styled.div`
  position: fixed;
  top: calc(50vh - 150px);
  right: calc(50vw - 10px);
  z-index: 999999;
`;

const LoaderOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #8080806b;
  z-index: 999999;
`;
