import { AnyAction } from "redux";
import { GET_HEADER, GET_MEGA_MENU } from "../actions";
import { PrimaryFontColor, PrimaryBgColor } from "../constants";
import { IHeaderState } from "../models";
import Logo from "../images/Logo.png";

const initialState: IHeaderState = {
  categories: [],
  logoUrl: Logo,
  headerBgColor: PrimaryBgColor,
  headerFontColor: PrimaryFontColor,
  megaMenu:[]
};
export function header(state: IHeaderState = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_HEADER:
      initialState.categories = action.data.data
      return { ...initialState, isFetching: false };
   //Action type for mega menu
      case GET_MEGA_MENU:
      initialState.megaMenu = action.data.data
      return { ...initialState, isFetching: false };
    default:
      return state;
  }
}
