import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import {
  LandingPage,
  LoginPage,
  SignUpPage,
  HomePage,
  GalleryPage,
  TermsAndConditionsPage,
  SpecificationPage,
  FundraisePage,
  CatalogPage,
  SpecialsPage,
  ClearancePage,
  ContactPage,
  CareerPage,
  FAQPage,
  AnnouncementPage,
  FontsPage,
  ColorsPage,
  SizeAndLocationPage,
  DesignPage,
  OrderPage,
  TermsPage,
  ProfilePage,
  PaymentPage,
} from "./pages";
import { Footer } from "./widgets/footer_v2";
import { Header } from "./widgets/header";
import { reduxStore } from "./reducer";
import styled from "styled-components";
import { defaultFontFamily } from "./constants";
import { LoginBoundary } from "./LoginBoundary";
import { ToastContainer } from "@happeouikit/toast";
import { ShirtDesigner } from "./widgets/shirtDesigner/ShirtDesigner";
import { ForgetPasswordPage } from "./pages/ForgotPasword";
import CreateMainLayout from "./pages/FooterPages/CreateLayout";
import { AboutUs, ambassador, career, contactUs, fundraiser, privacy, refundAndReturn, shipingAndPickup } from "./pages/FooterPages/StaticData.Constant";
export default class StitchZone extends React.Component {
  private nonLoggedInRoutes = (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forget-password" element={<ForgetPasswordPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/landing" element={<LandingPage />}></Route>
      <Route path="*" element={<LandingPage />}></Route>
      <Route
          path="/about-us"
          element={
            <CreateMainLayout
              heading="About Us"
              subHeadings={AboutUs}
            />
          }
        />
        <Route
          path="/privacy"
          element={
            <CreateMainLayout
              heading="PRIVACY"
              subHeadings={privacy}
            />
          }
        />
        <Route
          path="/career-opportunites"
          element={
            <CreateMainLayout
              heading="CAREER OPPORTUNITES"
              subHeadings={career}
            />
          }
        />
      <Route
          path="/ambassador-program"
          element={
            <CreateMainLayout
              heading="AMBASSADOR PROGRAM"
              subHeadings={ambassador}
            />
          }
        />
      <Route
          path="/fundraiser-program"
          element={
            <CreateMainLayout
              heading="FUNDRAISE"
              subHeadings={fundraiser}
            />
          }
        />
      <Route
          path="/shipping-and-pickup"
          element={
            <CreateMainLayout
              heading="SHIPPING & PICK-UP"
              subHeadings={shipingAndPickup}
            />
          }
        />
      <Route
          path="/refund-and-returns"
          element={
            <CreateMainLayout
              heading="REFUND & RETURNS"
              subHeadings={refundAndReturn}
            />
          }
        />
      <Route
          path="/Contact-us"
          element={
            <CreateMainLayout
              heading="CONTACT US"
              subHeadings={contactUs}
            />
          }
        />
    </Routes>
  );

  private loggedInRoutes = (
    <Routes>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forget-password" element={<ForgetPasswordPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/landing" element={<LandingPage />}></Route>
      <Route path="/careers" element={<CareerPage />} />
      <Route path="/faq" element={<FAQPage />}></Route>
      <Route
          path="/about-us"
          element={
            <CreateMainLayout
              heading="About Us"
              subHeadings={AboutUs}
            />
          }
        />
      <Route
          path="/privacy"
          element={
            <CreateMainLayout
              heading="PRIVACY"
              subHeadings={privacy}
            />
          }
        />
      <Route
          path="/career-opportunites"
          element={
            <CreateMainLayout
              heading="CAREER OPPORTUNITES"
              subHeadings={career}
            />
          }
        />
      <Route
          path="/ambassador-program"
          element={
            <CreateMainLayout
              heading="AMBASSADOR PROGRAM"
              subHeadings={ambassador}
            />
          }
        />
      <Route
          path="/fundraiser-program"
          element={
            <CreateMainLayout
              heading="FUNDRAISE"
              subHeadings={fundraiser}
            />
          }
        />
      <Route
          path="/shipping-and-pickup"
          element={
            <CreateMainLayout
              heading="SHIPPING & PICK-UP"
              subHeadings={shipingAndPickup}
            />
          }
        />
      <Route
          path="/refund-and-returns"
          element={
            <CreateMainLayout
              heading="REFUND & RETURNS"
              subHeadings={refundAndReturn}
            />
          }
        />
      <Route
          path="/Contact-us"
          element={
            <CreateMainLayout
              heading="CONTACT US"
              subHeadings={contactUs}
            />
          }
        />
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditionsPage />}
      ></Route>
      <Route path="terms" element={<TermsPage />}></Route>
      <Route path="/shipping-rates" element={<ColorsPage />}></Route>
      <Route path="/photo-gallery" element={<GalleryPage />}></Route>
      <Route path="/contact" element={<ContactPage />}></Route>
      <Route path="/specifications" element={<SpecificationPage />} />
      <Route path="/fundraise" element={<FundraisePage />} />
      <Route path="/my-profile" element={<ProfilePage />} />
      <Route path="/my-orders" element={<OrderPage />} />
      <Route path="/payment" element={<PaymentPage />}></Route>
      <Route
        path="/catalog/:categoryId/subcategories/:subCategoryId/products/:productId/design"
        element={<ShirtDesigner />}
      />
      <Route
        path="/catalog/:categoryId/subcategories/:subCategoryId/products"
        element={<CatalogPage />}
      />
      <Route
        path="/catalog/:categoryId/subcategories"
        element={<CatalogPage />}
      />
      <Route path="/catalog/:categoryId" element={<CatalogPage />} />
      <Route path="/catalog" element={<CatalogPage />} />
      <Route path="/specials" element={<SpecialsPage />} />
      <Route path="/clearance" element={<ClearancePage />} />
      <Route path="/designs" element={<DesignPage />} />
      <Route path="/sz-fonts" element={<FontsPage />} />
      <Route path="/colors" element={<ColorsPage />} />
      <Route path="/size-and-location" element={<SizeAndLocationPage />} />
      <Route
        path="*"
        element={
          <h1> Content to be updated </h1>
        }
      />
    </Routes>
  );

  render(): React.ReactNode {
    return (
      <AppContainer>
        <ToastContainer position="right" />
        <Provider store={reduxStore}>
          <AnnouncementPage />
          <BrowserRouter>
           <Header />
            <div style={{
              marginTop: `${(["/login", "/sign-up","password"].includes(window.location.pathname)  ) ? "0px" : (window.location.pathname.includes("catalog") ? "75px" : "90px")}`,
              marginBottom: `${(["/login","password"].includes(window.location.pathname) )? "0px" : (window.location.pathname === '/' || window.location.pathname.includes("catalog") ||  window.location.pathname.includes("sign-up") ||window.location.pathname.includes("faq") ? "" : "20px")}`
            }}>
              <LoginBoundary
                routesAvailableForNonLoggedInUsers={this.nonLoggedInRoutes}
                routesAvailableForLoggedInUsers={this.loggedInRoutes}
              ></LoginBoundary>
            </div>
            {true && <Footer isActive={true} />}
          </BrowserRouter>
        </Provider>
      </AppContainer>
    );
  }
}

const AppContainer = styled.div`
  font-family: ${defaultFontFamily};
  .bold {
    font-weight: bold;
  }
`;

/* * {
    font-family: ${defaultFontFamily};
  } */
