import React from "react";

const User = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 16"
      id="person"
    >
      <path
        fill="#ffffff"
        d="M11.5,8 C12.3284271,8 13,8.67157288 13,9.5 L13,10 C13,11.9714437 11.14049,14 8,14 C4.85950997,14 3,11.9714437 3,10 L3,9.5 C3,8.67157288 3.67157288,8 4.5,8 L11.5,8 Z M11.5,9 L4.5,9 C4.22385763,9 4,9.22385763 4,9.5 L4,10 C4,11.4376472 5.4321567,13 8,13 C10.5678433,13 12,11.4376472 12,10 L12,9.5 C12,9.22385763 11.7761424,9 11.5,9 Z M8,1.5 C9.51878306,1.5 10.75,2.73121694 10.75,4.25 C10.75,5.76878306 9.51878306,7 8,7 C6.48121694,7 5.25,5.76878306 5.25,4.25 C5.25,2.73121694 6.48121694,1.5 8,1.5 Z M8,2.5 C7.03350169,2.5 6.25,3.28350169 6.25,4.25 C6.25,5.21649831 7.03350169,6 8,6 C8.96649831,6 9.75,5.21649831 9.75,4.25 C9.75,3.28350169 8.96649831,2.5 8,2.5 Z"
      ></path>
    </svg>
  );
};

export default User;
