import React from "react";
import { ShirtDesigner } from "../widgets/shirtDesigner/ShirtDesigner";

interface HomePageProps {}

interface HomePageState {}

export class HomePage extends React.Component<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <>HomePage</>;
    // return <ShirtDesigner />;
  }
}
