import React from "react";
import {
  Container,
  Items,
  Item,
  Img,
  Desc,
  DesignHeader,
  DesignContent,
} from "./styled.component";
import { connect } from "react-redux";
import { spectificationClient } from "../../ApiClients";
import { IAppState, SpecificationResponseDatum } from "../../models";
import { Dispatch } from "redux";

interface DesignPageProps {
  getSpecification(): void;
  specification: any | SpecificationResponseDatum;
}

interface DesignPageState {
  active: number | null;
}

export class DesignPageComponent extends React.Component<
  DesignPageProps,
  DesignPageState
> {
  constructor(props: DesignPageProps) {
    super(props);
    this.state = {
      active: null,
    };
  }

  componentDidMount(): void {
    this.props.getSpecification();
  }

  handleAccordion = (id: number) => {
    this.setState({ active: id });
  };
  render() {
    let { embroidaryDesigns } = this.props.specification.specification;
    embroidaryDesigns = embroidaryDesigns.sort((a: any, b: any) => a.name.localeCompare(b.name));
    return (
      <Container>
        <DesignHeader>Embroidery Designs</DesignHeader>
        <DesignContent>
          If you would like for us to design your logo, crest or image, inquire
          in the comments section of your order form.
        </DesignContent>
        <DesignContent>
          We require an authorization letter on your organization's letterhead
          and a 300 dpi jpg of the image.
        </DesignContent>
        <DesignContent>
          Prices range from: Small 4" - $20 and up and Large 10-12" - $60 and
          up.
        </DesignContent>
        <Items>
          {embroidaryDesigns?.map((col: any) => {
            return (
              <Item>
                <Img src={col.image} alt="Image Not Available" />
                <Desc>{col.name}</Desc>
              </Item>
            );
          })}
        </Items>
      </Container>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    specification: state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getSpecification: () => spectificationClient.getSpecification(dispatch),
  };
};

export const DesignPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignPageComponent);
