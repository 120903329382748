import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { TextBeta, TinyText } from "@happeouikit/typography";
import {
  marginM,
  marginS,
  marginXL,
  paddingM,
  paddingS,
  primaryColor,
} from "../../../constants";
import {
  IAppliqueData,
  IAppState,
  ICustomizationOptions,
  IFontStyle,
  ILetterPlacement,
  IThreadToMatch,
} from "../../../models";
import { Image } from "../../../constants";
import { Dispatch } from "redux";
import { selectApplique } from "../../../actions";
import {
  IAppliqueID,
  IOneProductV2,
} from "../../../models/ApiResponses/IGetProductResponseV2";

interface Props {
  onNext(selectedApplique: IAppliqueData): void;
  onBack(): void;
  editCartProduct: any;
}

interface AppliquePropsFromRedux {
  availableForegroundOptions: ICustomizationOptions;
  availableBackgroundOptions: ICustomizationOptions;
  fontStyleOptions: Array<IFontStyle>;
  isCustomizationOptionsLoaded: boolean;
  availableThreadOptions: Array<IThreadToMatch>;
  appliques: IAppliqueData[];
  selectApplique(applique: IAppliqueID): void;
  selectedApplique?: IAppliqueData;
  appliqueId: IAppliqueID[];
}

interface State {}

class AppliqueSelector extends React.Component<
  Props & AppliquePropsFromRedux,
  State
> {

  constructor(props: Props & AppliquePropsFromRedux) {
    super(props);

    this.setPreferenceForEdit = this.setPreferenceForEdit.bind(this);
  }

  componentDidMount(): void {
    console.log("AppliqueSelector - componentDidMount");
    if(this.props.editCartProduct){
      this.setPreferenceForEdit();
    }
  }

  setPreferenceForEdit(){
    const {
      appliqueId,
      editCartProduct,
      selectApplique
    } = this.props;

    appliqueId.map((applique) => {
      if(applique.name.toLocaleUpperCase() === editCartProduct.customizedProducts.appliqueId.front.name.toLocaleUpperCase()){
        selectApplique(applique);
      }
    });
  }

  render(): React.ReactNode {
    const {
      appliques,
      onBack,
      onNext,
      selectedApplique,
      selectApplique,
      appliqueId,
    } = this.props;
    /*console.log(
      "🚀 ~ file: AppliqueSelector.tsx:50 ~ render ~ appliques:",
      appliques
    );*/

    return (
      <>
        <SettingHeader>
          <TextBeta className="bold">DESIGN STYLES</TextBeta>
          {/* <TinyText>
            Select your applique choices and preview your selection below.
            Twill/Material may show through if contrasting threads are used.
            Customized thread requires Double/Satin jk.
          </TinyText> */}
        </SettingHeader>
        <AppliqueList>
          {appliqueId.map((applique) => (
            <AppliqueOptions
              key={applique.name}
              onClick={() => selectApplique(applique)}
              isSelected={selectedApplique?._id === applique._id}
            >
              <img src={Image[applique._id]} alt={applique.name} />
              <p>{applique.name.toLocaleUpperCase()}</p>
            </AppliqueOptions>
          ))}
        </AppliqueList>
        <Divider></Divider>
        <CtaContainer>
          <ClearStepBtn onClick={onBack}>Previous</ClearStepBtn>
          {selectedApplique && (
            <NextpBtn onClick={() => onNext(selectedApplique)}>Next</NextpBtn>
          )}
        </CtaContainer>
      </>
    );
  }
}

const Divider = styled.div`
  height: 1px;
  background-color: #e3e3e3;
  margin: ${marginM} 0;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClearStepBtn = styled.button`
  margin-right: ${marginS};
  background: #dde3e6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
`;

const NextpBtn = styled.button`
  margin-right: ${marginS};
  background: ${primaryColor} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
  color: #ffffff;
`;

const SettingHeader = styled.div`
  margin-bottom: ${marginXL};
  p {
    margin-top: ${marginS};
  }
`;

const AppliqueList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
`;

const AppliqueOptions = styled.div<{ isSelected: boolean }>`
  width: 170px;
  height: 170px;
  text-align: center;
  border: 1px solid #dde3e6;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 2px 5px #00000029;
  margin-bottom: ${marginM};
  position: relative;
  cursor: pointer;

  img {
    width: 130px;
    height: 130px;
    object-fit: contain;
  }
  p {
    background: ${(props) =>
      props.isSelected
        ? "#142F8F 0% 0% no-repeat padding-box;"
        : "#efefef 0% 0% no-repeat padding-box"};
    border-radius: 15px 15px 0px 0px;
    padding: ${paddingS} 0;
    width: 100%;
    position: absolute;
    bottom: -20px;
    color: ${(props) => (props.isSelected ? "#ffffff" : "#424447")};
  }
`;

const mapStateToProps = (state: IAppState) => {
  const {
    appliqueOptions: {
      availableBackgroundOptions,
      availableForegroundOptions,
      fontStyleOptions,
      isCustomizationOptionsLoaded,
      availableThreadOptions,
    },
    appliques,
    selectedApplique,
  } = state.applique;

  return {
    availableBackgroundOptions,
    availableForegroundOptions,
    fontStyleOptions,
    isCustomizationOptionsLoaded,
    availableThreadOptions,
    appliques,
    selectedApplique,
  };
};

const mapDispatchToProps = (dispactch: Dispatch) => {
  return {
    selectApplique: (applique: IAppliqueData) =>
      dispactch(selectApplique(applique)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppliqueSelector);
