import React, { Component } from 'react';
import * as Style from './Styled.component';

// Define the type for menu items
interface MenuItem {
  id: number;
  label: string;
  route:string;
}

// Define the type for the props of the Sidebar component
interface SidebarProps {
  menuItems: MenuItem[];
  // heading: string;
}

// Sidebar component as a class component
class SideMenuBar extends Component<SidebarProps> {
  render() {
    const { menuItems } = this.props;

    return (
        <Style.BarSection>
      <Style.SidebarWrapper>
        {/* <Style.BarHeading>{heading}</Style.BarHeading> */}
        <Style.MenuList>
          {menuItems.map((item) => (
            <Style.MenuItemWrapper key={item.id}>
             <Style.Link href={item.route}> <Style.MenuButton >{item.label}</Style.MenuButton></Style.Link>
            </Style.MenuItemWrapper>
          ))}
        </Style.MenuList>
      </Style.SidebarWrapper>
      </Style.BarSection>
    );
  }
}
export default SideMenuBar;