import React, { ChangeEvent, useState } from "react";
import { IForgotPasswordRequestParams } from "../../models";
import { Validation } from "../../Utils";
import { StyledInput, Button } from "./styled.component";

interface ForgetPasswordFormProps {
  handleChangePassword(params: IForgotPasswordRequestParams): void;
}

export const ForgetPasswordForm: React.FC<ForgetPasswordFormProps> = ({ handleChangePassword }) => {
  const [ForgetPasswordParam, setForgetPasswordParams] = useState<IForgotPasswordRequestParams>(
    process.env.NODE_ENV === "development"
      ? {
          email: "superadmin@yopmail.com",
          path:'http://localhost:3000/forget-password'
        }
      : {
          email: "",
          path: window.location.href
        }
  );

  const [validationError, setValidationErrors] = useState({
    emailInvalidMsg: "",
  });

  const validateForm = () => {
    const { email } = ForgetPasswordParam;

    const { errorMessage: emailInvalidMsg } = Validation.validateEmail(email);

    setValidationErrors({
      emailInvalidMsg
    });

    return !emailInvalidMsg;
  };

  const validateAndLogin = () => {
    const { email,path} = ForgetPasswordParam;

    if (validateForm()) {
      const ForgetPasswordParams: IForgotPasswordRequestParams = {
        email,path
      };

      handleChangePassword(ForgetPasswordParams);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setForgetPasswordParams({ ...ForgetPasswordParam, email });

    setValidationErrors({
      ...validationError,
      emailInvalidMsg: Validation.validateEmail(email).errorMessage,
    });
  };


  return (
    <>
      <StyledInput
        type="text"
        placeholder="Username or Email"
        autoComplete="off"
        onChange={handleEmailChange}
        label={validationError.emailInvalidMsg}
      />
      <Button onClick={validateAndLogin}>Get Token</Button>
    </>
  );
};
